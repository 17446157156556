import './App.css'
import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import {
    createTheme,
    ThemeProvider,
    responsiveFontSizes,
} from '@mui/material/styles'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress';
import ReactGA from 'react-ga4';
import MapHomepage from './pages/MapHomepage'
import LearningLibPageWithInfScrolling from './pages/LearningLibPageWithInfScrolling'
import LearningLibNewestFirst from './pages/LearningLibPageNewestFirst'
import LoginPage from './pages/LoginPage'
import RegistrationPage from './pages/RegistrationPage'
import AdminDashboard from './pages/AdminDashboard'
import AdminEdit from './components/AdminEdit'
import NotFoundPage from './pages/NotFoundPage'
import SearchPage from './pages/SearchPage'
import CandidateProfilePage from './pages/CandidateProfilePage'
import ResponsiveAppBar from './components/ResponsiveAppBar'
import Footer from './components/Footer'
import Banner from './components/Banner'
import AboutPage from './pages/AboutPage'
import VoterChecklistPage from './pages/VoterChecklistPage'
import FeedbackPage from './pages/FeedbackPage'
import AdminFeedbackPage from './pages/AdminFeedbackPage'
import CandidateAdminPage from './pages/CandidateAdminPage';
import ProtectedRoute from './pages/ProtectedRoute';
import CandidateResultPage from './pages/CandidateResultPage';
import GoogleSearchHomePage from './pages/GoogleSearchHomePage';
import SearchResultsPage from './pages/SearchResultsPage';
import AddressPage from './pages/AddressPage';
import OtherKeyResults from './pages/OtherKeyResults'

ReactGA.initialize([
    { trackingId: 'G-ZLVG7NETWG' },
    { trackingId: 'G-1M7M0TRFHS' },
    { trackingId: 'G-K62YRZRY27' },
])

let fontAndColorTheme = createTheme({
    typography: {
        fontFamily: [
            '"Didact Gothic"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            'Arapey',
            'serif',
            '"Open Sans"',
        ].join(','),
        h1: {
            fontFamily: "'Arapey', 'serif'",
            fontSize: '4rem',
        },
        h2: {
            fontFamily: "'Open Sans', 'sans-serif'",
            fontSize: '2.2rem',
        },
        h4: {
            color: '#384747',
            fontFamily: "'Arapey', 'serif'",
        },
        body1: {
            color: '#384747',
            fontFamily: "'Didact Gothic', 'sans-serif'",
        },
        caption: {
            fontFamily: "'Didact Gothic', 'sans-serif'",
        },
    },
    palette: {
        primary: {
            main: '#C86734',
            dark: '#9C532A',
        },
        background: {
            main: '#F7F7F7',
            dark: '#989898',
        },
        secondary: {
            main: '#384747',
        },
        footerBackground: {
            main: '#EBEBEB',
        },
    },
})

fontAndColorTheme = responsiveFontSizes(fontAndColorTheme)

function App() {
    const [role, setRole] = useState('user')
    const [loading, setLoading] = useState(true)

    const getSession = async () => {
        try {
            const result = await fetch('/api/sessions')
            const dataResult = await result.json()
            if (dataResult) {
                setRole(dataResult.userRole)
            }
        } catch (err) {
            console.log('You are not authenticated')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getSession()
    }, [])

    const handleRole = (newRole) => {
        setRole(newRole)
    }

    return (
        <ThemeProvider theme={fontAndColorTheme}>
            <Box
                component="div"
                className="App"
                sx={{ backgroundColor: 'background.main' }}
            >
                <Banner />
                <ResponsiveAppBar role={role} handleRole={handleRole} />
                { loading && <div><CircularProgress size={120} color='info' /> Loading</div>}
                { loading === false && 
                <Routes>
                    <Route path="/" element={<MapHomepage />} />
                    <Route path="/addressPage" element={<AddressPage />} />
                    <Route
                        path="/learninglib"
                        element={<LearningLibPageWithInfScrolling />}
                    />
                    <Route
                        path="/learninglibnewfirst"
                        element={<LearningLibNewestFirst />}
                    />
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                        path="/registration"
                        element={<RegistrationPage />}
                    />
                    <Route path="/search/:keyword" element={<SearchPage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route
                        path="/candidatePage/:id"
                        element={<CandidateProfilePage />}
                    />
                    
                    <Route path="/feedback" element={<FeedbackPage />} />
                    < Route
                        path="/voter-checklist"
                        element={<VoterChecklistPage />}
                    />

                        <Route path="/feedback" element={<FeedbackPage />} />
                        <Route
                            path="/voter-checklist"
                            element={<VoterChecklistPage />}
                        />

                        <Route
                            path="/candidateResult"
                            element={<CandidateResultPage />}
                        />

                        <Route
                            path="/otherKeyStates"
                            element={<OtherKeyResults />}
                        />

                        {/* <Route element={<ProtectedRoute role={role} />}> */}
                        <Route
                            path="/admin/candidate"
                            element={<CandidateAdminPage />}
                        />
                        {/* </Route> */}
                        <Route element={<ProtectedRoute role={role} />}>
                            <Route
                                path="/admin/library"
                                element={<AdminDashboard role={role} />}
                            />
                        </Route>
                        <Route element={<ProtectedRoute role={role} />}>
                            <Route
                                path="/adminedit/:id"
                                element={<AdminEdit role={role} />}
                            />
                        </Route>
                        <Route element={<ProtectedRoute role={role} />}>
                            <Route
                                path="/admin/feedback"
                                element={<AdminFeedbackPage role={role} />}
                            />
                        </Route>

                        <Route element={<ProtectedRoute role={role} />}>
                            <Route
                                path="/admin/search"
                                element={<GoogleSearchHomePage />}
                            />
                        </Route>
                        <Route element={<ProtectedRoute role={role} />}>
                            <Route
                                path="/admin/searchResults"
                                element={<SearchResultsPage />}
                            />
                        </Route>

                        <Route
                            path="*"
                            element={<NotFoundPage role={role} />}
                        />

                </Routes>

                }
                <Footer />
            </Box>
        </ThemeProvider>
    )
}

export default App
