import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export default function Sources({sources}) {
    return (
        <Box my={3}>
            <Typography variant="h2">Sources</Typography>
            { sources && sources.map( source => (
                <Box sx={{textAlign: 'left'}} mb={1}>
                    <Link variant="body1" key={source} href={source}>{source}</Link>
                </Box>
            ))}
        </Box>
    )
}

Sources.propTypes = {
    sources: PropTypes.arrayOf(PropTypes.string).isRequired
  }