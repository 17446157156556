import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function AddKeyword({handleAdd}) {
  const [open, setOpen] = useState(false);
  const [wordValue, setWordValue] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleWordValue = (event) => {
    setWordValue(event.target.value);
  }

  const handleAddWord = (wordInput) => {
      handleAdd(wordInput);
      handleClose();
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add Keyword
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Keyword</DialogTitle>
        <DialogContent>          
          <TextField
            autoFocus
            margin="dense"
            id="keyword"            
            label="Keyword"            
            fullWidth
            variant="standard"     
            value={wordValue}   
            onChange={handleWordValue}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleAddWord(wordValue)}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AddKeyword.propTypes = {    
    handleAdd: PropTypes.func.isRequired    
}