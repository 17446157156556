import React, {useState, useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import CandidateResult from '../components/CandidateResult';

export default function CandidateResultPage() {

    const [candidateList, setCandidateList] = useState();
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [errorMsg, setErrorMsg] = useState();
    
    const getListOfCandidates = async(formInputParam) => {
        try {
            setErrorMsg();
            setLoading(true);
            window.localStorage.removeItem('candidateResult');
            const res = await fetch("/api/newCandidate/getDistrict", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'                    
                },
                body: JSON.stringify(formInputParam)
            });
            const data = await res.json();
            if (data.success) {
                setCandidateList(data.data);
                console.log(data.data);
                const candidateIds = []
                data.data.forEach( (oneData) => oneData.forEach( oneCandidate => {
                    candidateIds.push(oneCandidate._id);
                }));
                const searchString = `candidateResult?street=${searchParams.get('street')}&city=${searchParams.get('city')}&state=${searchParams.get('state')}&fullState=${searchParams.get('fullState')}&postalcode=${searchParams.get('postalcode')}`
                window.localStorage.setItem('candidateResult', JSON.stringify({searchString, candidateIds}));                
            } else {
                setErrorMsg(`Loading of candidates failed`);
            }
        } catch (err) {
            console.log(err);
            setErrorMsg(`Cannot load results, cause: ${err}`)
        } finally {
            setLoading(false);
        }
    }

    useEffect( () => {
        const formInputs = { 
            street: searchParams.get('street'),
            city: searchParams.get('city'),
            state: searchParams.get('state'),
            fullState: searchParams.get('fullState'),    
            postalcode: searchParams.get('postalcode')
        };

        getListOfCandidates(formInputs);

    }, []);

    return (
        
        <Box sx={{width: "80%", margin: '0 auto'}}>
            { loading && <CircularProgress /> }
            { candidateList && <CandidateResult candidates={candidateList} />}
            { errorMsg && <Typography> {errorMsg} </Typography> }
        </Box>
        
    )
}
