import React from 'react'
import Box from '@mui/material/Box'
import Countdown from './Countdown'

export default function Banner() {    

    return (
        <Box component="div" sx={{ py: 3, backgroundColor: "primary.dark", textAlign: 'center'}}>
            {/* < Typography variant="body1" sx={{
                mb: 1, fontStyle: 'italic', color: 'white', fontWeight: 'bold'
            }}>Midterm 2022 Content for 3 States is Ready!</Typography>              */}
            <Countdown countdownDate={new Date("2024/11/05")} countdownSubject="GENERAL ELECTION"/>

            
            
        </Box>
    )
}


