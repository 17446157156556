import React, { useState } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';


// TODO: this package's dependencies are potentially broken, seems to work ok right now
export default function SearchField() {
    const [currSearchValue, setCurr] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        if (event.key === "Enter") {
            navigate(`/search/${currSearchValue}`)
        }
    }

    return (
        <Box>
            <TextField
                sx={{width: {xs: "100%", md: "50%"}}}                            
                placeholder="Search the Library"
                id="fullWidth" 
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                }}
                value={currSearchValue}
                onChange={(e) => setCurr(e.target.value)} 
                onKeyPress={(e) => handleSubmit(e)}
            />
        </Box>
    )
}
