import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CandidateSection from '../../components/CandidateSection';

const initialState = [    
    { office: "House of Representatives", district: "Florida 10", candidates: []},
    { office: "House of Representatives", district: "Illinois 1", candidates: []},
    { office: "House of Representatives", district: "Illinois 3", candidates: []},
    
    { office: "House of Representatives", district: "Louisiana 1", candidates: []},
    { office: "House of Representatives", district: "Michigan 3", candidates: []},
    { office: "House of Representatives", district: "Michigan 7", candidates: []},
    { office: "House of Representatives", district: "Michigan 8", candidates: []},

    { office: "House of Representatives", district: "Nevada 1", candidates: []},
    { office: "House of Representatives", district: "Nevada 3", candidates: []},
    { office: "House of Representatives", district: "Nevada 4", candidates: []},    
    { office: "House of Representatives", district: "Texas 35", candidates: []},
    
    { office: "Senate", district: "Florida", candidates: []},
    { office: "Senate", district: "Louisiana", candidates: []},    
    { office: "Senate", district: "Nevada", candidates: []},
    { office: "Senate", district: "North Carolina", candidates: []},
    { office: "Senate", district: "Oklahoma", candidates: []},    
    { office: "Senate", district: "Wisconsin", candidates: []},
    
    { office: "Governor", district: "Arkansas", candidates: []},
    { office: "Governor", district: "Georgia", candidates: []},    
    { office: "Governor", district: "Michigan", candidates: []},
    { office: "Governor", district: "Nevada", candidates: []},
    { office: "Governor", district: "Oklahoma", candidates: []},
    { office: "Governor", district: "Texas", candidates: []},
    { office: "Governor", district: "Wisconsin", candidates: []},

    { office: "AG", district: "Michigan", candidates: []},    
    { office: "AG", district: "Nevada", candidates: []}, 
    { office: "AG", district: "Wisconsin", candidates: []}
    
]
export default function OtherKeyResults() {
    const [checked, setChecked] = useState(false);
    const [candidateList, setCandidateList] = useState(initialState);

    const getKeyResults = async() => {
        const promisesArray = [];
        const results = [...initialState];
        results.forEach( oneResult => {
            const onePromise = axios.get(`/api/newCandidate?office=${oneResult.office}&district=${oneResult.district}`);
            promisesArray.push(onePromise);
        })
        const allResults = await Promise.all(promisesArray);
        
        allResults.forEach( (oneResult, index) => {
            results[index].candidates = oneResult.data.data;
        });
                
        setCandidateList(results);

        const candidateIds = []
        results.forEach( (result) => result.candidates.forEach( oneCandidate => {
            candidateIds.push(oneCandidate._id);
        }));
        const searchString = "otherKeyStates";
        window.localStorage.setItem('candidateResult', JSON.stringify({searchString, candidateIds}));                
    }

    useEffect( () => {
        getKeyResults();
    }, [])
    
    return (
        <Box sx={{width: "80%", margin: '0 auto'}}>
            <Typography variant="h1" my={2}>Candidate List</Typography>
            { candidateList && candidateList.map( oneRow => (
                <CandidateSection heading={oneRow.office === 'AG' ? 'Attorney-General' : oneRow.office} candidates={oneRow.candidates} districtName={oneRow.district}  />
            ))}
            
            <Box component="div" 
            sx={{ position: 'fixed', bottom: 0, right: 0, p: 2, backgroundColor: "#f5f5f5", border: '1px solid black', cursor: 'pointer'}}
            onClick={ () => setChecked(!checked)}
            >
                <Collapse in={checked} collapsedSize={20}>
                    <Typography mb={2}>LEGEND</Typography>
                    <Typography align="left">Blue - Democrat</Typography>
                    <Typography align="left">Red - Republican</Typography>
                    <Typography align="left">Green - Progressive</Typography>
                    <Typography align="left">Brown - Independent</Typography>
                </Collapse>
            </Box>         
        </Box>
    )
}

