import React, { useCallback, useState, useRef } from 'react'
// import Typography from '@mui/material/Typography' 
import Box from '@mui/material/Box' 
import StoryCard from '../components/StoryCard'
import loadMoreVideos from '../hooks/loadMoreVideos'
import Page from '../components/Page'
import Scroll from '../components/Scroll'


export default function LearningLibPageNewestFirst() {    
    const [pageNumber, setPageNumber] = useState(1);
    const newestFirstArg = true;    
    const { loading, videos, hasMore } = loadMoreVideos(pageNumber, '', newestFirstArg);
    const observer = useRef();
    const lastSectionRef = useCallback( node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if(entries[0].isIntersecting && hasMore) {
                console.log('Visible');
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    return (        
        <Page>            
            <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            { videos?.map( (book, index) => (
                <Box component="div" ref={videos.length === index+1 ? lastSectionRef : undefined} sx={{mb: 3, mr: 2}}>
                    <StoryCard
                    /* eslint-disable-next-line no-underscore-dangle */
                    key={book._id}
                    /* eslint-disable-next-line no-underscore-dangle */
                    itemId={book._id} // NOTE: itemId is required for track items
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    image={book.thumbnail_url}
                    url={book.url}
                    noMargin
                    />                                            
                    { videos.length === index + 1 && <Scroll showBelow={200} />}
                </Box>

            ))}
            </Box>
        </Page>
    )
}
