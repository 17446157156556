import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

export default function UpdateDropDown() {
  const [open, setOpen] = useState(false);
  const [listOfStates, setListOfStates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getStates = async () => {
    const response = await fetch(`/api/dropdown`);
    const result = await response.json();          
    setLoading(false);   
    if (result.success) {
        setListOfStates(result.data);        
    }    
}

useEffect( () => {
    getStates();
}, []);

const handleChecked = (event, indexOfState) => {
    const newArray = [...listOfStates];    
    newArray[indexOfState] = {...newArray[indexOfState], stateActive: event.target.checked};
    setListOfStates(newArray);
}

const handleSave = async() => {
    setErrorMsg();
    const response = await fetch(`/api/dropdown`, {
        method: 'PUT',     
        headers: { 'content-type': 'application/json' },       
        body: JSON.stringify(listOfStates)
    });        
    const result = await response.json();  
    if (result.success) {                
        handleClose();
    } else {
        setErrorMsg(result.data);
        handleClose();
    }
}

  return (
    <Box sx={{ mb: 2 }}>
      <Button variant="outlined" onClick={handleClickOpen}>
        Update DropDown
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Choose Active States</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tick the states which should be listed in the dropdown
          </DialogContentText>
          { errorMsg && <div>Error Msg: {errorMsg}</div>}
          { loading && <div>Loading ....</div>}
          
          <FormGroup>
            { listOfStates && listOfStates.map( (oneState, index) => (
                
                <FormControlLabel key={oneState.stateShort} control={<Checkbox checked={oneState.stateActive} onChange={(event) => handleChecked(event, index)} />} label={oneState.stateFull} />              
                
            ))}
          </FormGroup>
          

        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
