import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteKeyword from './DeleteKeyword';
import AmendKeyword from './AmendKeyword';
import AddKeyword from './AddKeyword';

export default function ListKeywords() {
    const [keywords, setKeywords] = useState([]);

    async function fetchKeywords(){
        try {
            setKeywords([]);
            const res = await fetch(`/api/searchKeyword`, {
                method: 'GET',
                headers: { 'content-type': 'application/json' },
            })
            const d = await res.json()
            if (d.success) {            
                console.log(d.data);
                setKeywords(d.data);
            }            
        } catch (err) {
            console.log(err)
        }
    }

    const handleDelete = async(deletedWord, id) => {
        try {            
            const res = await fetch(`/api/searchKeyword/${id}`, {
                method: 'DELETE',
                headers: { 'content-type': 'application/json' },
            })
            const d = await res.json()
            if (d.success) {                                            
                setKeywords(keywords.filter((word) => word.keyword !== deletedWord));
            }  
        } catch (err) {
            console.log(err);
        }
    }

    const handleAmend = async(amendedWord, id) => {
        try {            
            const res = await fetch(`/api/searchKeyword/${id}`, {
                method: 'PUT',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({keyword: amendedWord})
            })
            const d = await res.json()
            if (d.success) {                     
                const tempKw = [...keywords];
                const amendedKwIndex = tempKw.findIndex((chosenWord) => chosenWord._id === id);
                tempKw[amendedKwIndex] = d.data;
                setKeywords(tempKw);                
            }  
        } catch (err) {
            console.log(err);
        }
    }

    const handleAdd = async(addedWord) => {
        try {            
            const res = await fetch(`/api/searchKeyword`, {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({keyword: addedWord})
            })
            const d = await res.json()
            if (d.success) {    
                const newArray = [...keywords];
                newArray.push(d.data);
                setKeywords(newArray);
            }  
        } catch (err) {
            console.log(err);
        }
    }

    useEffect( () => {
        fetchKeywords();
    }, []);

    return (
        <Box component="div" sx={{textAlign: "left"}}>
            <Typography variant="h5" sx={{my: 2}}>The keywords used are:</Typography>
            
            {keywords && keywords.map( (item, index) => (
                <Box component="div" key={item.keyword} sx={{display: "flex", alignItems: "center", gap: "5px"}}>
                    <Typography sx={{my: 2}}>{index+1}. {item.keyword}</Typography>                    
                    <AmendKeyword handleAmend={handleAmend} wordItem={item.keyword} id={item._id} />
                    <DeleteKeyword handleDelete={handleDelete} wordItem={item.keyword} id={item._id} />                
                </Box>
            ))}
            <AddKeyword handleAdd={handleAdd} />
        </Box>
    )
}
