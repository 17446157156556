import React from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import BillCategory from './BillCategory';


export default function VotingHistory({candidateParam}) {
    const categories = [        
        "Covid-19",
        "Criminal Justice",
        "Economy",
        "Education",
        "Environment", 
        "Foreign Policy",
        "Gov Regulations",
        "Gov Spending",        
        "Healthcare", 
        "Housing Justice", 
        "Immigration", 
        "Infrastructure",
        "Labor Rights", 
        "Racial Justice",
        "Voting Regulations"
    ];    

    return (
        <Box component="div" my={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography variant='h2'>Voting History</Typography>
            <Box component='div' my={2} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '90%'}}>
                { categories.map( (category) => (
                    <BillCategory key={category} topic={category} candidateParam={candidateParam} />
                ))}
            </Box>
        </Box>
    )
}

VotingHistory.propTypes = {    
    candidateParam: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        district: PropTypes.string.isRequired,
        office: PropTypes.string.isRequired
    }).isRequired
}