import React, { useState, useReducer } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import reducer from '../utils/reducer'

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
        >
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}.
        </Typography>
    )
}

const initialState = {
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
}

export default function RegistrationPage() {
    const [formInputs, dispatch] = useReducer(reducer, initialState)
    const [errors, setErrors] = useState({})
    const [backEndErrorMsg, setBackEndErrorMsg] = useState({})

    const handleInputChange = (inputEvent) => {
        dispatch({
            type: 'update',
            payload: {
                field: inputEvent.target.name,
                value: inputEvent.target.value,
            },
        })
    }

    const findFormErrors = () => {
        const { name, email, password, passwordConfirmation } = formInputs
        const newErrors = {}
        const verifyEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i

        //  name error
        if (!name || name === '') {
            newErrors.name = 'cannot be blank!'
            newErrors.nameError = true
        }

        //  email error
        if (!email || email === '') {
            newErrors.email = 'cannot be blank!'
            newErrors.emailError = true
        } else if (verifyEmail.test(email) === false) {
            newErrors.email = 'not a valid email address'
            newErrors.emailError = true
        }

        // password error
        if (!password || password === '') {
            newErrors.password = 'cannot be blank!'
            newErrors.passwordError = true
        } else if (password.length < 8) {
            newErrors.password = 'password must be at least 8 characters long'
            newErrors.passwordError = true
        }

        // confirm password error
        if (!passwordConfirmation || passwordConfirmation === '') {
            newErrors.passwordConfirmation = 'cannot be blank!'
            newErrors.passwordConfirmationError = true
        } else if (passwordConfirmation.length < 8) {
            newErrors.passwordConfirmation =
                'password must be at least 8 characters long'
            newErrors.passwordConfirmationError = true
        } else if (password !== passwordConfirmation) {
            newErrors.passwordConfirmation = 'password must be the same'
            newErrors.passwordConfirmationError = true
        }

        return newErrors
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setErrors({})
        setBackEndErrorMsg({})
        // get our new errors
        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {
            // We've got errors on the front end
            setErrors(newErrors)
        } else {
            const response = await fetch('/api/users', {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(formInputs),
            })
            const data = await response.json()
            if (data.success) {
                console.log(data.data);
                window.location.assign('/login');
            } else {
                console.log('fail to register')
                console.log(data.data)
                if (data.data.includes('E11000')) {
                    setBackEndErrorMsg({
                        ...backEndErrorMsg,
                        email: 'Email already in use',
                    })
                }
            }
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Register
                </Typography>

                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="email"
                        autoFocus
                        onChange={handleInputChange}
                        helperText={errors?.email !== '' ? errors.email : ''}
                        error={errors?.emailError}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoFocus
                        onChange={handleInputChange}
                        helperText={errors?.name !== '' ? errors.name : ''}
                        error={errors?.nameError}
                    />
                    {backEndErrorMsg.email !== '' && (
                        <Typography
                            variant="p"
                            sx={{ marginBottom: '15px', color: 'error.main' }}
                        >
                            {backEndErrorMsg.password}
                            {backEndErrorMsg.email}
                        </Typography>
                    )}
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleInputChange}
                        helperText={
                            errors?.password !== '' &&
                            errors?.password !== undefined
                                ? errors.password
                                : 'Password must be 8 characters long'
                        }
                        error={errors?.passwordError}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="passwordConfirmation"
                        label="passwordConfirmation"
                        type="password"
                        id="passwordConfirmation"
                        autoComplete="current-password"
                        onChange={handleInputChange}
                        helperText={
                            errors?.passwordConfirmation !== '' &&
                            errors?.passwordConfirmation !== undefined
                                ? errors.passwordConfirmation
                                : 'Password must be 8 characters long'
                        }
                        error={errors?.passwordConfirmationError}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Register
                    </Button>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    )
}
