import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import Typography from '@mui/material/Typography' 
import Box from '@mui/material/Box' 
import Page from '../components/Page'
import StoryCard from '../components/StoryCard'

export default function SearchPage() {
    const [bookResults, setBookResults] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const {keyword} = useParams();

    useEffect( async () => {
        setBookResults([]);
        setNoResults(false);
        const result = await axios.get(`/api/books?search=${keyword}`);
        if (result.status === 200) {
            if (result.data.data.length > 0) {
                setBookResults(result.data.data);
            } else {
                setBookResults([]);
                setNoResults(true);
            }
        }
        return () => {
            setBookResults([]);
        }
    }, [keyword])

    return (
        <Page>
            <Typography variant="h2" color='primary.main' sx={{margin: '2rem 0 2rem 0px'}}>Search Results</Typography>
            { noResults && <p>No Books Found.</p>}
            <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            { bookResults?.map(book => (
                <StoryCard
                /* eslint-disable-next-line no-underscore-dangle */
                key={book._id}
                /* eslint-disable-next-line no-underscore-dangle */
                itemId={book._id} // NOTE: itemId is required for track items
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                image={book.thumbnail_url}
                url={book.url}
            />
            ))}
            </Box>
        </Page>
    )
}
