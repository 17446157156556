import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';

export default function LogoutMenu({handleRole}) {
    
    const handleLogout = () => {
        const deleteSession = async() => {
            const result = await fetch("/api/sessions", {
                method: 'DELETE'
            });
            const data = await result.json();            
            if (data.success) {
               handleRole('user');
               window.location.assign('/');
            }
          }
          deleteSession();
    }
    return (
        <MenuItem onClick={handleLogout}>
            Logout
        </MenuItem>
    )
}

LogoutMenu.propTypes = {    
    handleRole: PropTypes.func.isRequired
  }