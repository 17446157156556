import {useEffect, useState} from 'react';
import axios from 'axios';

export default function loadMoreVideos(pageNumber, category="", newestFirst=false) {    
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [videos, setVideos] = useState([]);
    const [categoryVideos, setCategoryVideos] = useState([]);
    const [categorySection, setCategorySection] = useState('');
    const [sections, setSections] = useState([]);

    const loadVideos = async (page, cat="", newestFirstParam=false) => {

        if (newestFirstParam === true) {
            try {
                setCategoryVideos([]);
                setCategorySection('');
                const result = await axios.get(`/api/books/page/${page}?newestFirst=${newestFirstParam}`);
                const newVideos = result.data.data;                
                setVideos((prevVideos) => [...prevVideos, ...newVideos]);                
                setHasMore(result.data.hasMore);
                setLoading(false);
            } catch (err) {
                throw new Error('Error on server side');        
            }
        }
        
        if (cat==="" && newestFirstParam === false) {   
                          
            const result = await axios.get(`/api/books/page/${page}`);
            if (result.status !== 200) {
                
                throw new Error('Error on server side');        
            } else {
                setCategoryVideos([]);
                setCategorySection('');
                const newVideos = result.data.data; 
                const temp = result.data.data.map((video) => video.category);            
                const newCategories = [...new Set(temp)];               
                setSections((prevCat) => [...prevCat, ...newCategories]);
                setVideos((prevVideos) => [...prevVideos, ...newVideos]);                
                setHasMore(result.data.hasMore);
                setLoading(false);
            }
        } else if (cat !== "" && newestFirstParam === false) {
            const result = await axios.get(`/api/books?cat=${cat}`);
            if (result.status !== 200) {
                throw new Error('Error on server side');        
            } else {                
                setVideos([]);
                setSections([]);
                const newVideos = result.data.data;                
                setCategoryVideos([...newVideos]);
                setCategorySection(cat);
                setHasMore(false);
                setLoading(false);
            }
        }
        
    };

    useEffect(async() => {
        setLoading(true);
        await loadVideos(pageNumber, category, newestFirst);
    }, [pageNumber, category])

    return { loading, videos, sections, hasMore, categoryVideos, categorySection };
}
