import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';

export default function AmendKeyword({handleAmend, wordItem, id}) {
  const [open, setOpen] = useState(false);
  const [wordValue, setWordValue] = useState(wordItem);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleWordValue = (event) => {
    setWordValue(event.target.value);
  }

  const handleAmendWord = (wordInput) => {
      handleAmend(wordInput, id);
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Amend Keyword</DialogTitle>
        <DialogContent>          
          <TextField
            autoFocus
            margin="dense"
            id="keyword"            
            label="Keyword"            
            fullWidth
            variant="standard"     
            value={wordValue}   
            onChange={handleWordValue}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleAmendWord(wordValue)}>Amend</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AmendKeyword.propTypes = {    
    handleAmend: PropTypes.func.isRequired,
    wordItem: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
}