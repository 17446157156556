import React from 'react'

export default function ArrowRight () {
  /* Credit https://www.svgrepo.com/svg/55316/arrow-pointing-a-circle */
  /* Public domain. */
  /* Orig viewBox="0 0 895.8 895.8" */
  /* Orig width="895.8px" height="895.8px" */
  /*
    xmlns="http://www.w3.org/2000/svg" is not needed because we are using this
    inline rather than as a whole document. But it was in the original.
  */
  const origPoints = [
    [286.2, 677.3],
    [613.3, 447.899],
    [286.2, 218.5],
    [286.2, 363],
    [0.1,   362.8],
    [0,     532.8],
    [286.2, 533]
  ];
  const ourPoints = origPoints; /* Keep them as they are for now. */
  const pointsString = ourPoints.reduce(
    (prev, cur) => `${prev}${cur.join(",")} `
  ,
    ""
  );
  const ourWidth  = 613.3;
  const ourHeight = 677.3;
  const heightInRem = 2;
  const widthInRem = heightInRem * ourWidth / ourHeight;
    /* Mantain aspect ratio */
  const svgStyle = {
    height: `${heightInRem}rem`,
    width:  `${widthInRem }rem`,
    display: "inline-block",
    paddingLeft: "6px", paddingRight: "6px",
    verticalAlign: "middle",
    position: "relative", bottom: "5px" /* Hack */
  };
  const spanStyle = {
    position: "relative",
    display: "inline-block",
    verticalAlign: "middle"
  };
  return <span style={spanStyle}>
    < svg
      style={svgStyle}
      version="1.1"
      width="613.3px" height="677.3px" viewBox="0 0 613.3 677.3"
    >
      <polygon points={pointsString} />
    </svg>
  </span>
};

