import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function DeleteKeyword({handleDelete, wordItem, id}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteFunc = () => {
      handleDelete(wordItem, id);
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <DeleteForeverIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
            Are you sure you want to delete the keyword?
        </DialogTitle>        
        <DialogActions>
          <Button onClick={handleDeleteFunc}>Yes</Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteKeyword.propTypes = {    
    handleDelete: PropTypes.func.isRequired,
    wordItem: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
}