import React, { useState, useReducer } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import PropTypes from 'prop-types'
import reducer from '../../utils/reducer'
import matQnsReducer from '../../utils/matQnsReducer'
import questions from '../../data/matrixQuestions.json'
import CustomToolbar from '../../utils/QuillToolbar'
import candidateOfficeOptions from "../../utils/CandidateOfficeOptions"

const initialQns = {
    laborRights: {
        workerProtection: '',
        childcare: '',
        minWageIncrease: '',
    },
    criminalJustice: {
        policingBudget: '',
        demilPoliceForce: '',
        mandMin: '',
        legalDrug: '',        
    },
    education: {
        diversity: '',
        sexEd: '',
    },
    electionReg: {
        voterProtect: '',
        campaignFin: '',
    },
    environment: {        
        fossilFuel: '',
        greenNewDeal: '',
    },
    healthcare: {
        roeWade: '',        
        mediAll: '',
    },
    immigration: {
        citizenship: '',       
    },
    housingJustice: {    
        publicHousing: '',
    },    
    economy: {
        wealthTax: ''    
    },
    foreignPolicy: {
        gaza: ''    
    },
    other: {
        affordableHousing: '',
        citizenshipPath: '',    
        backgroundChecks: '',    
        broadBand: '',    
        wealthTax: '',    
    },    
}

const states = ["N/A","Arizona", "Georgia", "New York","Ohio", "Pennsylvania", "Wisconsin"]

export default function AddEditCandidate({
    row,
    typeOfButton,
    addCandidate,
    handleAlert,
    editCandidate,
}) {
    const initialMatrixQns = row.matrixQuestions
        ? {...row.matrixQuestions, ...initialQns}
        : initialQns
        const [open, setOpen] = useState(false)
        const [errors, setErrors] = useState({})
        const [selectedState, setSelectedState] = useState("")
        const [stateSpecificOfficeOptions, setStateSpecificOfficeOptions] = useState(null)
        const [formInputs, dispatch] = useReducer(reducer, row)
        const [matrixQns, dispatch2] = useReducer(matQnsReducer, initialMatrixQns)
        const tableCols = [
            'fullName',
            'firstName',
            'lastName',
            'party',
            'incumbent',
            'district',
            'polLeaning',
            'office',
            "state"
        ]

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

   const handleSelectState = (event) => {
        const state = event.target.value;
        setSelectedState(state)
        setStateSpecificOfficeOptions(candidateOfficeOptions[state] || null);
    };

    const handleInputChange = (inputEvent) => {
        dispatch({
            type: 'update',
            payload: {
                field: inputEvent.target.name,
                value:
                    inputEvent.target.name === 'sources' &&
                    inputEvent.target.value.includes(',')
                        ? inputEvent.target.value.split(',')
                        : inputEvent.target.value,
            },
        })
    }

    const handleMatrixQns = (category, inputEvent) => {
        dispatch2({
            type: 'update',
            payload: {
                category,
                field: inputEvent.target.name,
                value: inputEvent.target.value,
            },
        })
    }

    const handleQuillChange = (fieldParam, valueParam) => {
        dispatch({
            type: 'update',
            payload: {
                field: fieldParam,
                value: valueParam
            },
        })
    }

    // handle frontend errors
    const findFormErrors = () => {
        const {
            fullName,
            firstName,
            lastName,
            party,
            incumbent,
            district,
            headshot,
            bio,
            polLeaning,
            office,
        } = formInputs

        const newErrors = {}

        if (!fullName || fullName === '') {
            newErrors.fullName = 'cannot be blank!'
            newErrors.fullNameError = true
        } else if (fullName.match(/[^#%&*:<>?/{|}]+/) === false) {
            newErrors.fullName = 'contains invalid characters'
            newErrors.fullNameError = true
        }

        if (!firstName || firstName === '') {
            newErrors.firstName = 'cannot be blank!'
            newErrors.firstNameError = true
        } else if (firstName.match(/[^#%&*:<>?/{|}]+/) === false) {
            newErrors.firstName = 'contains invalid characters'
            newErrors.firstNameError = true
        }

        if (!lastName || lastName === '') {
            newErrors.lastName = 'cannot be blank!'
            newErrors.lastNameError = true
        } else if (lastName.match(/[^#%&*:<>?/{|}]+/) === false) {
            newErrors.lastName = 'contains invalid characters'
            newErrors.lastNameError = true
        }

        if (!party || party === '') {
            newErrors.party = 'cannot be blank!'
            newErrors.partyError = true
        }

        if (!incumbent || incumbent === '') {
            newErrors.incumbent = 'cannot be blank!'
            newErrors.incumbentError = true
        }

        if (!district || district === '') {
            newErrors.district = 'cannot be blank!'
            newErrors.distrctError = true
        } else if (district.match(/[^#%&*:<>?/{|}]+/) === false) {
            newErrors.district = 'contains invalid characters'
            newErrors.districtError = true
        }

        if (!headshot || headshot === '') {
            newErrors.headshot = 'cannot be blank!'
            newErrors.headshotError = true
        } else if (
            headshot.includes('https://') === false &&
            headshot.includes('http://') === false &&
            headshot !== 'NA'
        ) {
            newErrors.headshot =
                'entry must start with https:// or http://. Else enter NA'
            newErrors.headshotError = true
        }

        if (!bio || bio === '') {
            newErrors.bio = 'cannot be blank!'
            newErrors.bioError = true
        } else if (
            bio.includes('https://') === false &&
            bio.includes('http://') === false &&
            bio !== 'NA'
        ) {
            newErrors.bio =
                'entry must start with https:// or http://. Else enter NA'
            newErrors.bioError = true
        }

        if (!polLeaning || polLeaning === '') {
            newErrors.polLeaning = 'cannot be blank!'
            newErrors.polLeaningError = true
        }

        if (!office || office === '') {
            newErrors.office = 'cannot be blank!'
            newErrors.officeError = true
        }

        return newErrors
    }

    const handleSave = async (type) => {
        setErrors({})
        // get our new errors
        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {
            // We've got errors on the front end
            setErrors(newErrors)
        } else {
            const finalInput = {
                ...formInputs,
                matrixQuestions: matrixQns,
            }

            if (type === 'Edit') {
                const response = await fetch(`/api/newCandidate/${row._id}`, {
                    method: 'PUT',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify(finalInput),
                })
                const result = await response.json()
                if (result.success) {
                    editCandidate(row._id, finalInput)
                    handleAlert(true)
                    handleClose()
                } else {
                    handleAlert(false)
                    handleClose()
                }
            } else {
                const response = await fetch(`/api/newCandidate`, {
                    method: 'POST',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify(finalInput),
                })
                const result = await response.json()
                if (result.success) {
                    addCandidate(result.data)
                    handleAlert(true)
                    handleClose()
                } else {
                    handleAlert(false)
                    handleClose()
                }
            }
        }
    }

    const tableHeaders = [
        'Full Name',
        'First Name',
        'Last Name',
        'Party',
        'Incumbent',
        'District',
        'Pol Leaning',
        'Office',
        "State"
    ]

    return (
        <div>
            <Button
                variant="contained"
                color={typeOfButton === 'Edit' ? 'secondary' : 'info'}
                onClick={handleClickOpen}
            >
                {typeOfButton}
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
                <DialogTitle>{typeOfButton} Candidate</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter all the compulsory inputs and click on Save
                    </DialogContentText>

                    <TableContainer component={Paper} sx={{ my: 2 }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {tableHeaders.map((header) => (
                                        <TableCell
                                            sx={{ fontWeight: 'bold' }}
                                            key={header}
                                        >
                                            {header}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    key={row.fullName}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    {tableCols.map((col) => {
                                        if (col === 'party') {
                                            return (
                                                <TableCell key={col}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="partyId">
                                                            Party
                                                        </InputLabel>
                                                        <Select
                                                            labelId="partyId"
                                                            id="partyId1"
                                                            value={
                                                                formInputs[col]
                                                            }
                                                            label="Party"
                                                            name={col}
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            error={
                                                                errors?.partyError
                                                            }
                                                            helpertext={
                                                                errors.party
                                                            }
                                                        >
                                                            <MenuItem value="Democrat">
                                                                Democrat
                                                            </MenuItem>
                                                            <MenuItem value="Republican">
                                                                Republican
                                                            </MenuItem>
                                                            <MenuItem value="Others">
                                                                Others
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                            )
                                        }

                                        if (col === 'incumbent') {
                                            return (
                                                <TableCell key={col}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="incumbentId">
                                                            Incumbent
                                                        </InputLabel>
                                                        <Select
                                                            labelId="incumbentId"
                                                            id="incumbentId1"
                                                            value={
                                                                formInputs[col]
                                                            }
                                                            label="Incumbent"
                                                            name={col}
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            error={
                                                                errors?.incumbentError
                                                            }
                                                            helpertext={
                                                                errors.incumbent
                                                            }
                                                        >
                                                            <MenuItem value="Incumbent">
                                                                Incumbent
                                                            </MenuItem>
                                                            <MenuItem value="Challenger">
                                                                Challenger
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                            )
                                        }

                                        if (col === 'polLeaning') {
                                            return (
                                                <TableCell key={col}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="polLeaningId">
                                                            Political Leaning
                                                        </InputLabel>
                                                        <Select
                                                            labelId="polLeaningId"
                                                            id="polLeaningId1"
                                                            value={
                                                                formInputs[col]
                                                            }
                                                            label="polLeaning"
                                                            name={col}
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            error={
                                                                errors?.polLeaningError
                                                            }
                                                            helpertext={
                                                                errors.polLeaning
                                                            }
                                                        >
                                                            <MenuItem value="Progressive">
                                                                Progressive
                                                            </MenuItem>
                                                            <MenuItem value="Democrat">
                                                                Democrat
                                                            </MenuItem>
                                                            <MenuItem value="Republican">
                                                                Republican
                                                            </MenuItem>
                                                            <MenuItem value="Independent">
                                                                Independent
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                            )
                                        }

                                        if (col === 'office') {
                                            return (
                                                <TableCell key={col}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="officeId">
                                                            Office
                                                        </InputLabel>
                                                        <Select
                                                            labelId="officeId"
                                                            id="officeId1"
                                                            value={
                                                                formInputs[col]
                                                            }
                                                            label="office"
                                                            name={col}
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            error={
                                                                errors?.officeError
                                                            }
                                                            helpertext={
                                                                errors.office
                                                            }
                                                        >

                                                            {stateSpecificOfficeOptions || 
                                                                [
                                                                    <MenuItem value="House of Representatives">
                                                                        House of
                                                                        Representatives
                                                                    </MenuItem>,
                                                                    <MenuItem value="Senate">
                                                                        Senate
                                                                    </MenuItem>,
                                                                    <MenuItem value="Governor">
                                                                        Governor
                                                                    </MenuItem>,
                                                                    <MenuItem value="AG">
                                                                        Attorney-General
                                                                    </MenuItem>,
                                                                    <MenuItem value="State Senate">
                                                                        State Senate
                                                                    </MenuItem>,
                                                                    <MenuItem value="State HOR">
                                                                        State HOR
                                                                    </MenuItem>
                                                                ]
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                            )
                                        }

                                        if (col === 'state') {
                                            return (
                                                <TableCell key={col}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="stateId">
                                                            State
                                                        </InputLabel>
                                                        <Select
                                                            labelId="stateId"
                                                            id="stateId1"
                                                            value={
                                                                selectedState
                                                            }
                                                            label="state"
                                                            name={col}
                                                            onChange={
                                                                handleSelectState
                                                            }
                                                        >
                                                            { states.map(state => (
                                                                <MenuItem key={state} value={state}>{state}</MenuItem>
                                                            ))}      
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                            )
                                        }

                                        return (
                                            <TableCell key={col}>
                                                <TextField
                                                    value={formInputs[col]}
                                                    name={col}
                                                    onChange={handleInputChange}
                                                    error={
                                                        errors
                                                            ? errors[
                                                                  `${col}Error`
                                                              ]
                                                            : false
                                                    }
                                                />
                                                <FormHelperText
                                                    sx={{ color: 'red' }}
                                                >
                                                    {errors[col]}
                                                </FormHelperText>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mb={2}>
                        <TextField
                            label="Headshot"
                            placeholder="Headshot"
                            size="small"
                            name="headshot"
                            value={formInputs.headshot}
                            onChange={handleInputChange}
                            error={errors?.headshotError}
                            fullWidth
                        />
                        <FormHelperText sx={{ color: 'red' }}>
                            {errors.headshot}
                        </FormHelperText>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="Bio"
                            placeholder="Bio"                
                            size="small"
                            name="bio"
                            value={formInputs.bio}
                            onChange={handleInputChange}
                            error={ errors?.bioError } 
                            fullWidth
                        />
                        
                        <FormHelperText sx={{ color: 'red' }}>
                            {errors.bio}
                        </FormHelperText>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            id="Sources"
                            label="Sources"
                            fullWidth
                            multiline
                            placeholder="Separate sources with comma"
                            maxRows={4}
                            name="sources"
                            value={
                                Array.isArray(formInputs.sources)
                                    ? formInputs.sources.join()
                                    : formInputs.sources
                            }
                            onChange={handleInputChange}
                        />
                        
                    </Box>
                    <Box mb={2}>
                        {/* <TextField
                            id="specialNotes"
                            label="Analysis"
                            fullWidth
                            multiline
                            placeholder="Analysis"
                            maxRows={4}
                            name="specialNotes"
                            value={formInputs.specialNotes}
                            onChange={handleInputChange}
                        /> */}
                        <CustomToolbar
                            title="Analysis"
                            field="specialNotes"                            
                            handleQuillChange={handleQuillChange}
                            boxValue={ formInputs.specialNotes }
                        />
                    </Box>

                {/* only certain offices need questions */}
                    {['Senate', 'House of Representatives', 'State HOR', 'State Senate', 'State Assembly'].includes(formInputs.office) && 
                    questions.map((question, index) => (
                        matrixQns[question.category] && 
                        <Box mb={2} key={question.short}>
                        <FormControl
                        disabled={
                            (row.office === 'AG' &&
                                question.forAG === false) ||
                                (row.office !== 'AG' &&
                                    question.short === 'extraJudicial')
                                }
                                >
                                <FormLabel
                                id="demo-row-radio-buttons-group-label"
                                sx={{ fontWeight: 'bold' }}
                                    >
                                    {index + 1}. {question.long}
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name={question.short}
                                    defaultValue="Unclear"
                                    value={
                                        matrixQns[question.category][
                                            question.short
                                        ]
                                    }
                                    onChange={(event) =>
                                        handleMatrixQns(
                                            question.category,
                                            event
                                        )
                                    }
                                    >
                                    <FormControlLabel
                                        value="Unclear"
                                        control={<Radio />}
                                        label="Unclear"
                                        />
                                    <FormControlLabel
                                        value="Yes"
                                        control={<Radio />}
                                        label="Yes"
                                        />
                                    <FormControlLabel
                                        value="No"
                                        control={<Radio />}
                                        label="No"
                                        />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    ))}
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => handleSave(typeOfButton)}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

AddEditCandidate.propTypes = {
    row: PropTypes.shape({
        _id: PropTypes.string,
        fullName: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        party: PropTypes.string,
        incumbent: PropTypes.string,
        district: PropTypes.string,
        headshot: PropTypes.string,
        bio: PropTypes.string,
        polLeaning: PropTypes.string,
        office: PropTypes.string,
        matrixQuestions: PropTypes.shape({
            laborRights: PropTypes.shape({
                workerProtection: PropTypes.string,
                childcare: PropTypes.string,
                minWageIncrease: PropTypes.string,
                retirementAge: PropTypes.string,
            }),
            criminalJustice: PropTypes.shape({
                policingBudget: PropTypes.string,
                demilPoliceForce: PropTypes.string,
                mandMin: PropTypes.string,
                legalDrug: PropTypes.string,                
            }),
            education: PropTypes.shape({
                diversity: PropTypes.string,
                sexEd: PropTypes.string,
            }),
            electionReg: PropTypes.shape({
                voterProtect: PropTypes.string,
                campaignFin: PropTypes.string,
            }),
            environment: PropTypes.shape({                
                fossilFuel: PropTypes.string,
                greenNewDeal: PropTypes.string,
            }),
            healthcare: PropTypes.shape({
                roeWade: PropTypes.string,                
                mediAll: PropTypes.string,
            }),
            immigration: PropTypes.shape({
                citizenship: PropTypes.string                
            }),
            housingJustice: PropTypes.shape({                
                publicHousing: PropTypes.string,
            }),            
            economy: PropTypes.shape({
                wealthTax: PropTypes.string,                
            }), 
            foreignPolicy: PropTypes.shape({
                gaza: PropTypes.string,   
            }),
            other: PropTypes.shape({
                affordableHousing: PropTypes.string,   
                citizenshipPath: PropTypes.string,    
                backgroundChecks: PropTypes.string,   
                broadBand: PropTypes.string,    
                wealthTax: PropTypes.string,                             
            }),          
        }),
    }).isRequired,
    typeOfButton: PropTypes.string.isRequired,
    addCandidate: PropTypes.func.isRequired,
    handleAlert: PropTypes.func.isRequired,
    editCandidate: PropTypes.func.isRequired,
}
