import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReactQuill from 'react-quill'
import PropTypes from 'prop-types'
import 'quill/dist/quill.snow.css'

export default function CustomToolbar({ title, field, handleQuillChange, boxValue }) {
    // const [htmlString, setHTMLString] = useState('')
    const [htmlString, setHTMLString] = useState(boxValue);

    const handleBoxChange = (fieldParam, valueParam) => {
        setHTMLString(valueParam);
        handleQuillChange(fieldParam, valueParam)
    }

    const formats = [      
        'font',  
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'script',
        'list',
        'bullet',
        'indent',        
        'link',        
        'align',
    ]

    const modules = {
        toolbar: [            
            [{ font: [] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'script': 'sub'}, {'script': 'super'}],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
            ['clean']
          ],
    }
        
    return (
        <Box
            sx={{
                p: 2,                
                borderRadius: 5,
            }}
        >
            <Typography
                sx={{
                    mb: 1,
                }}
            >
                {title}
            </Typography>            
            <ReactQuill
                theme="snow"
                placeholder={`Write the ${title.toLowerCase()} here. All links must start with http:// or https://`}
                formats={formats}
                modules={modules}     
                value={htmlString}           
                onChange={(value) => handleBoxChange(field, value)}
            />
            <Box
                sx={{
                    justifyContent: 'flex-end',
                }}
            />                
            
        </Box>
    )
}

CustomToolbar.propTypes = {
    title: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,    
    handleQuillChange: PropTypes.func.isRequired,
    boxValue: PropTypes.string.isRequired
}
