import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import SocialIcons from './SocialIcons'

export default function Footer() {
    
    return (
        <Box sx={{ backgroundColor: '#EBEBEB', p: 2 }}>
            <Box component="div" sx={{ display: { xs: 'block', md: 'none' } }}>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: '#9C532A' }}
                    href='/feedback'
                >
                    Send Us Feedback!
                </Button>
                <Box
                    component="div"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 4,
                    }}
                >
                    <Box component="div">
                        <Typography variant="caption">
                            &#169; Copyright 2024
                        </Typography>
                        <br />
                        <Typography variant="caption">
                            The DownBallot
                        </Typography>
                    </Box>
                    <Box>
                        <SocialIcons />
                    </Box>
                </Box>
            </Box>

            <Box
                component="div"
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    component="div"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '30%',
                        alignItems: 'start',
                    }}
                >
                    <Typography variant="h4" align="left">
                        THE
                        <br />
                        DOWNBALLOT
                    </Typography>
                    <SocialIcons />
                    <Typography variant="body1">
                        &#169; Copyright 2024 The DownBallot
                    </Typography>
                </Box>
                <Box
                    component="div"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '30%',
                        alignItems: 'end',
                        justifyContent: 'space-evenly',
                    }}
                >
                    <Button
                        variant="contained"
                        href='/feedback'
                        sx={{
                            backgroundColor: '#9C532A',
                            width: '60%',
                            textTransform: 'capitalize',
                        }}
                    >
                        Send Us Feedback!
                    </Button>
                    <Typography variant="body1" align="right">
                        Enjoyed navigating thedownballot.org? Think that things
                        could be better?
                    </Typography>
                    <Typography variant="body1" align="right">
                        We would love to know what you think to make it better
                        for next election season!
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
