import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

export default function DeleteCandidate({id, removeCandidate}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (idInput) => {
    const response = await fetch(`/api/newCandidate/${idInput}`, {
        method: 'DELETE',     
        headers: { 'content-type': 'application/json' }
    });      

    const result = await response.json();    
    if (result.success) {
        removeCandidate(idInput)
        handleClose();
    }

    console.log('after delete', result);
  }

  return (
    <div>
      <Button variant="contained" color="error" onClick={handleClickOpen}>
        Delete
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete A Candidate From Database</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the candidate?
          </DialogContentText>          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleDelete(id)}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteCandidate.propTypes = {    
    id: PropTypes.string.isRequired,
    removeCandidate: PropTypes.func.isRequired
}