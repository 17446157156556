import React from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
// import Header from './Header'
import Banner from './Banner'
import Header2 from './Header2'

export default function Page(props) {
    const { bannerText, children } = props

    return (
        
        
        <Box p={2}>
            {bannerText !== '' && <Banner text={bannerText} />}
            {/* <Header /> */}            
            <Header2 />
            {children}                       
        </Box>        
        
    )
}

Page.defaultProps = {
    bannerText: '',
}

Page.propTypes = {
    bannerText: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
}
