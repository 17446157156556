import React, {useState, useEffect} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import PropTypes from 'prop-types';
import CandidateSection from './CandidateSection';

export default function CandidateResult({candidates}) {
    const [checked, setChecked] = useState(false);
    const [organizedCandidates, setOrganizedCandidates] = useState({})
    
    useEffect(()=>{
        const candidatesByTitle = {}
        candidates.forEach((c)=>{
            if(c.length === 0) return
            if(!candidatesByTitle[c[0].office]) candidatesByTitle[c[0].office] = c
        })

        setOrganizedCandidates(candidatesByTitle)
    },[candidates])

    return (
        <div>
            <Typography variant="h1" my={2}>Candidate List</Typography>
            {Object.keys(organizedCandidates).map((c)=>(
                c.length > 0 && <CandidateSection key={c} heading={c} candidates={organizedCandidates[c]}/>
            )
            )}
            <Box component="div" 
            sx={{ position: 'fixed', bottom: 0, right: 0, p: 2, backgroundColor: "#f5f5f5", border: '1px solid black', cursor: 'pointer'}}
            onClick={ () => setChecked(!checked)}
            >
                <Collapse in={checked} collapsedSize={20}>
                    <Typography mb={2}>LEGEND</Typography>
                    <Typography align="left">Blue - Democrat</Typography>
                    <Typography align="left">Red - Republican</Typography>
                    <Typography align="left">Green - Progressive</Typography>
                    <Typography align="left">Brown - Independent</Typography>
                </Collapse>
            </Box>         
        </div>
    )
}

CandidateResult.propTypes = {
    candidates: PropTypes.instanceOf(Array).isRequired
}