import { useState, useEffect } from "react";

function useGoogleSearch(term) {
  const [dataResponse, setDataResponse] = useState(null);

  const fetchData = async () => {
    fetch(
      `/api/googleSearch?candidateSearch=${term}`
    )
      .then((response) => response.json())
      .then((result) => {
        setDataResponse(result);
      });
  };

  useEffect(() => {    
    fetchData();
  }, [term]);

  return { dataResponse };
}

export default useGoogleSearch;
