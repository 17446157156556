import React from 'react'
import ReactGA from 'react-ga4';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';


export default function CandidateProfile({candidate, candidatePage}) {
    
    // const DOB = new Date(candidate.notes[1]);
    // const age = (new Date()).getFullYear() - DOB.getFullYear().toString();
    const handleClickEvent = () => {        
        ReactGA.event({ 
            action: 'candidateClick',
            category: 'click_candidate',
            label: `${candidate.firstName} ${candidate.lastName} (${candidate.party}) ${candidate.office} ${candidate.district}`
        });
    }

    let borderColor; 

    if (candidate.polLeaning === "Democrat") {
        borderColor = "blue";
    } else if (candidate.polLeaning === "Republican") {
        borderColor = "red";
    } else if (candidate.polLeaning === "Progressive") {
        borderColor = "#55825E";
    } else {
        borderColor = "#C86734";
    }
   
    
    let stylePic = {
        width: candidatePage ? {xs: "120px"} : {xs: "60px", sm: "140px"},     
        height: candidatePage ? {xs: "120px"} : {xs: "60px", sm: "140px"},         
        backgroundImage: `url(${candidate?.headshot})`, 
        backgroundRepeat: 'no-repeat', 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        borderRadius: '50%', 
        border: `4px solid ${borderColor}`,    
        cursor: candidatePage ? 'default' : 'pointer'      
    };

    stylePic = candidate?.headshot === 'NA' ? {...stylePic, backgroundImage: `url(/profile-placeholder.png)`} : {...stylePic};

    // <Link href={`/candidatePage/${candidate.firstName}-${candidate.lastName}?state=${candidate.district}&typeOfElection=${typeOfElection}`} >
    return (
        
        <Box component="div" sx={ candidatePage ? { display: 'flex', flexDirection: 'column', alignItems: 'center' } : {py: 2, mr: 2, width: {xs: '25%', sm: '60%'}, maxWidth: "300px"}}>            
            { !candidatePage && 
            <Link href={`/candidatePage/${candidate._id}`} sx={{ width: "100%", display: 'flex', justifyContent: 'center'}} onClick={handleClickEvent}>                
                <Box mb={1} component="div" id={candidate?.id} sx={stylePic}/>                
            </Link>
            }
            { candidatePage && 
                <Box mb={1} component="div" id={candidate?.id} sx={stylePic}/>
            }
            <Typography mb={2} align='center' variant="h4" component="h2" sx={{ fontSize: {xs: "12px", sm: "14px", md: "16px" }}}>{candidate?.firstName} {candidate?.lastName}</Typography>                        
            { candidatePage && 
                <>
                <Typography mb={1} align="left">{candidate?.district}</Typography>
                <Typography mb={1} align="left">{candidate?.party}</Typography>
                <Typography mb={1} align="left">{candidate?.office === "State HOR" ? "State Assembly" : candidate?.office }</Typography>                
                <Typography align="left"><Link underline="hover" href={candidate?.bio}>Bio Profile</Link></Typography>
                </>
            }
        </Box>
            
      
    )
}

CandidateProfile.propTypes = {
    candidate: PropTypes.instanceOf(Object).isRequired,
    candidatePage: PropTypes.bool.isRequired,       
}
