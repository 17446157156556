import React        from 'react';
import TilePage     from "../components/TilePage";

const rightsBody = [ 'div',
  [ 'p',
    "If you are visually impaired or have a disability that impacts your " +
    "ability to cast a vote, you have the right to request an accessible " +
    "version of the absentee ballot . Click ",
    ['a', {href: "https://nycabsentee.com/"}, "here"], " to learn more"
  ],
  [ 'p',
    "As stated by ",
    ['a', {href: "http://elections.ny.gov/"}, "elections.ny.gov"],
    ", \"New York State employees are eligible for up to two hours of paid " +
    "time off to vote if they do not have “sufficient time to vote.” An " +
    "employee is deemed to have “sufficient time to vote” if an employee " +
    "has four consecutive hours to vote either from the opening of the " +
    "polls to the beginning of their work shift, or four consecutive " +
    "hours between the end of a working shift and the closing of the polls\""
  ],
  [ 'p',
    "According to the office of the New York State Attorney General, \"a " +
    "person convicted of a felony in the State of New York, a federal " +
    "court, or in another state for a crime or offense which would " +
    "constitute a felony in New York can vote if:"
  ],
  [ 'ul',
    [ 'li',
      "He/she has been pardoned or restored to the rights of citizenship " +
      "by the governor, or"
    ],
    ['li', "His/her maximum sentence of imprisonment has expired, or"],
    ['li', "He/she has been discharged from parole."],
    ['li', "Click ",
      ['a', {
        href: "https://ag.ny.gov/civil-rights/voting-rights"
      }, "here"], " to read more"
    ],
  ], [ 'p', "Click ",
    ['a', {href: "https://vote.nyc/page/voters-rights"}, "here"],
    " to read about New York State Voter's Bill of Rights"
  ], [ 'p', "People on parole have the right to vote immediately upon " +
    "their release without further need to complete the additional ",
    [ 'a',
      { href:
        "https://www.nbcnews.com/politics/politics-news/" +
        "new-york-passes-bill-automatically-restore-voting-rights-" +
        "people-parole-n1265008"
      },
      "pardon application"
    ]
  ], [ 'p', "Click ",
    ['a', {href: "https://vote.nyc/page/voters-rights"}, "here"],
    " to read about New York State Voter's Bill of Rights"
  ]
];

const IRVDogwhistle = [ 'ul',
  [ 'li', "Ranked Choice Voting will not be in effect in the November 2nd" +
    " general election.​​"
  ]
];

const recentlyMovedBody = [ 'ul',
  [ 'li',
    "Once registered, you are permanently eligible to vote unless you " +
    "moved outside the city or county in which you were registered"
  ],
  [ 'li', "Click ",
    ['a', {href: "https://vote.nyc/page/am-i-registered"}, "here"],
    " to ensure you're properly registered to vote in your new county"
  ] 
];

const impDatesBody = [ 'ul',
  ['li', "Nov. 1 — Last day to request an absentee ballot in person."],
  [ 'li',
    "Nov. 2 — Election Day and the last day to postmark your absentee ballot."
  ]
];

const howVoteBody = [ 'ul',
  ['li', "Early voting has begun and will last until Sunday, October 31st. To determine your designated early voting poll site, search your address here."],
  ['li', "The last day to postmark a ballot is Tuesday, November 2nd."],
  ['li', "The last day to deliver a ballot in-person to the local board of elections (by someone other than the voter) is Tuesday, November 2nd."],
  [ 'li',
    "Click ",
    ['a', {href: "https://vote.nyc/page/absentee-voting-0"}, "here"],
    " to apply for an absentee ballot"
  ]
];

const idRqtsBody = [ 'ul',
  [ 'li', "Registered voters are not required to bring their ID to the " +
    "polling station. However, if you did not submit your identification" +
    " when registering, you will be required to show your ID in order to" +
    " vote."
  ],
  [ 'li', "First-time voters must provide identification either on election" +
    " day or upon registering to vote."
  ],
  ['li', "The following forms of identification are acceptable:"],
  [ 'ul',
    ['li', "Driver's license number"],
    ['li', "Non-driver's ID number"],
    ['li', "Last four digits of your social security number​"],
  ],
  ['li', "In the event that you did not provide the last four digits of" +
    " your Social Security Number or your driver's license number, be sure" +
    " to attach the following with your application for voter registration:"
  ],
  [ 'ul',
    ['li', "Current and Valid Photo ID"],
    ['li', "Current Utility Bill"],
    ['li', "Bank Statement"],
    ['li', "Government Check or Paycheck"],
    ['li', "Government Document that shows Name and Address​"],
  ],
  [ 'li',
    "Click ",
    ['a', {href: "https://vote.nyc/page/voter-id"}, "here"],
    " to learn more about the identification you need in order to vote!"
  ],
  [ 'li', "Click ",
    ['a', {href: "https://vote.nyc/page/voter-qualifications"}, "here"],
    " to learn about voter qualifications."],
];

/*
  In the Voter Checklist, unlike in the About page, the popup title in every
  case equals the tile title.
*/
const TILE_SPECS_BY_NAME = {
  DATES: {
    tileTitle: "Important Dates",
    serializableDetailsBody: impDatesBody
  },
  ID: {
    tileTitle: "Voter ID Requirements",
    serializableDetailsBody: idRqtsBody
  },
  HOW: {
    tileTitle: "How to Vote",
    serializableDetailsBody: howVoteBody
  },
  MOVED: {
    tileTitle: "Recently Moved?",
    serializableDetailsBody: recentlyMovedBody
  },
  IRV: {
    tileTitle: "Ranked Choice Voting",
    serializableDetailsBody: IRVDogwhistle
  },
  RIGHTS: {
    tileTitle: "Voter Rights",
    serializableDetailsBody: rightsBody
  }
};

const servicesToChildren = {
  tileTitleForName:      (_verb, name) => TILE_SPECS_BY_NAME[name].tileTitle,
  expansionTitleForName: (_verb, name) => TILE_SPECS_BY_NAME[name].tileTitle,
  serializableDetailsBodyForName:
    (_verb, name) => TILE_SPECS_BY_NAME[name].serializableDetailsBody,
  overarchingIdeaName: () => 'DATES',
  subordinateIdeaNamesInOrder:
                    () => ['ID', 'HOW', 'MOVED', 'IRV', 'RIGHTS']
};
const self = (verb, ...args) => {
  const hit = servicesToChildren[verb];
  if (! hit) throw new Error(`Unknown verb ${JSON.stringify(verb)}!`);
  return hit(verb, ...args)
};

const VoterChecklistPage = function VoterChecklistPage () {
  return < TilePage parent={self} titleText="Ready to vote?"
    subtitleText=
      "Click on each tile to make sure you're ready for November 2nd!"
  />
};

export default VoterChecklistPage
