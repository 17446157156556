const matQnsReducer = (state, action) => {
    const { type } = action
    switch (type) {
        case 'update':            
            return {
                ...state,
                [action.payload.category]: {
                    ...state[action.payload.category],
                    [action.payload.field]: action.payload.value
                },
            }
        default:
            return state
    }
}

export default matQnsReducer;
