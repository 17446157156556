import React, { useState, useReducer } from 'react'
// import {useNavigate} from 'react-router-dom';
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import reducer from '../utils/reducer'

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
        >
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}.
        </Typography>
    )
}

const initialState = {
    email: '',
    password: '',
}

export default function LoginPage() {
    const [formInputs, dispatch] = useReducer(reducer, initialState)
    const [errors, setErrors] = useState({})
    const [backEndErrorMsg, setBackEndErrorMsg] = useState({})
    // const navigate = useNavigate();

    // TODO: Error handling
    console.log(backEndErrorMsg)


    const handleInputChange = (inputEvent) => {
        dispatch({
            type: 'update',
            payload: {
                field: inputEvent.target.name,
                value: inputEvent.target.value,
            },
        })
    }

    const findFormErrors = () => {
        const { email, password } = formInputs
        const newErrors = {}
        const verifyEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i

        // email error
        if (!email || email === '') {
            newErrors.email = 'cannot be blank!'
            newErrors.emailError = true
        } else if (verifyEmail.test(email) === false) {
            newErrors.email = 'not a valid email address'
            newErrors.emailError = true
        }

        // password error
        if (!password || password === '') {
            newErrors.password = 'cannot be blank!'
            newErrors.passwordError = true
        } else if (password.length < 8) {
            newErrors.password = 'password must be at least 8 characters long'
            newErrors.passwordError = true
        }

        return newErrors
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('button was pressed');
        console.log(formInputs);

        setErrors({})
        setBackEndErrorMsg({})
        // get our new errors
        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {
            // We've got errors on the front end
            setErrors(newErrors)
        } else {
            const response = await fetch('/api/sessions', {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify(formInputs),
            })
            const data = await response.json()
            if (data.success) {
                console.log(data.data);                
                window.location.assign('/');
            } else {
                console.log('fail to register')
            }
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleInputChange}
                        helperText={errors?.email !== '' ? errors.email : ''}
                        error={errors?.emailError}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleInputChange}
                        helperText={
                            errors?.password !== '' ? errors.password : ''
                        }
                        error={errors?.passwordError}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSubmit}
                    >
                        Log In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/registration" variant="body2">
                                Don&#39;t have an account? Sign Up
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    )
}
