import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export default function BillTracker({url}) {
    const [stageSelected, setStageSelected] = useState('Introduced');
    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoading] = useState(false);
    const stages = ['Introduced', 'Passed House', 'Passed Senate', 'Resolving Differences', 'To President', 'Became Law'];

    const getBillProgress = async () => {
        
        try {
            setLoading(true);
            const billProgress = await fetch(`/api/bill/progress?url=${url}`);
            const result = await billProgress.json();
            
            if (result.success) {
                setStageSelected(result.data);
            } else {
                setErrorMsg('Fail to get bill progress')
            }
        } catch (err) {
            setErrorMsg(`Server error: ${err.message}`);
        } finally {
            setLoading(false);
        }
        
    }

    useEffect( () => {  
        getBillProgress();
    }, [url])

    const getStyle = (stageInput) => {        
        const style = {
            fontSize: '12px',          
            border: '1px solid black',
            borderRadius: '5px',
            color: stageInput === stageSelected ? 'white' : 'black',
            backgroundColor: stageInput === stageSelected ? '#333333' : 'white',
        }
        return style;
    }

  return (
    <Box my={2} sx={{ display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
      <Link href={url} my={2}>Source</Link>
      { loading && <div>Loading Bill Progress...</div> } 
      <Stack
        direction="row"
        divider={<ArrowForwardIosIcon />}
        spacing={1}
        sx={{alignItems: 'center' , flexWrap: 'wrap'}}
      >
          { stages.map(stage => (
              <Typography key={stage} px={1} sx={getStyle(stage)}>{stage}</Typography>
          ))}        
      </Stack>
      
      { errorMsg && 
      <div>
        <Typography>{errorMsg}</Typography> 
      </div>}
    </Box>
  );
}

BillTracker.propTypes = {
    url: PropTypes.string.isRequired,
}