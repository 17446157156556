import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export default function NotFoundPage() {
    return (
        <Box sx={{p: 2}}>
            <Typography variant="h1"> 404: Page Not Found</Typography>
        </Box>
    )
}
