import React from 'react'
import PropTypes from 'prop-types'
// import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { styled } from '@mui/material/styles'
// import CardContent from '@mui/material/CardContent'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
// import Grid from '@mui/material/Grid'
// import { useNavigate } from 'react-router-dom'
// import { CardActionArea } from '@mui/material'
// import useScript from '../hooks/useScript'

// TODO: prop definitions
// TODO: additional orientation logic?
export default function StoryCard(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // const navigate = useNavigate()
    // useScript('https://www.tiktok.com/embed.js')
    const { image, url, noMargin } = props
    
    // const onClick = () => {
    //     navigate('/story', { state: props })
    // }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 320,
        height: { xs: 560, md: 780},
        bgcolor: 'black',
        border: '1px solid #000',
        boxShadow: 24,
        p: 1,
      };

    const Backdrop = styled('div')`
      z-index: -1;
      position: fixed;
      right: 0;
      bottom: 0;
      top: 0;
      left: 0;
      background-color: rgba(56, 56, 56, 0.8);
      -webkit-tap-highlight-color: transparent;
    `;
    
    const CardAdjust = styled('div')(({ theme }) => ({ 
        marginRight: noMargin ? '0px': '50px',  
        backgroundColor: 'background.main',                        
        [theme.breakpoints.down('sm')]: {
            width: noMargin ? '210px' : '60%', minWidth: '200px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '40%', minWidth: '180px', maxWidth: '190px'
        },
        [theme.breakpoints.up('lg')]: {
            width: '25%', minWidth: '160px', maxWidth: '170px'
        },
      }));
      
    const ImageAdjust = styled('img')(() => ({
        border: '0',  
        borderRadius: '5px',      
        width: '100%'        
      }));
    
    const IframeAdjust = styled('iframe')(() => ({
        border: '0px',        
        width: '100%', 
        height: '100%'
                
    }));

    const BoxPlayBtn = styled('div')(({ theme }) => ({ 
        position: 'absolute',                                                                                 
        zIndex: 3,
        "&:hover": {
            opacity: 0.7
        },
        cursor: 'pointer',          
        [theme.breakpoints.down('sm')]: {
            top: "40%", left: "30%",
        },
        [theme.breakpoints.up('sm')]: {
            top: "30%", left: "25%",
        },
        [theme.breakpoints.up('lg')]: {
            top: "30%", left: "25%"
        },
      }));

    return (
        <>
            <CardAdjust> 
                <Box
                    component="div"
                    sx={{overflow: 'hidden', position: 'relative'}}
                    onClick={handleOpen}
                >
                    <ImageAdjust src={image} />
                    
                    <BoxPlayBtn>
                        <PlayArrowRoundedIcon 
                        sx={{
                            color: "#EBEBEB", 
                            fontSize: "6rem", 
                            }} 
                        />
                    </BoxPlayBtn>
                    
                    {/* <IframeAdjust src={url.replace('@thedownballot.org/video', 'embed')} title={url} allowFullScreen scrolling="no" /> */}
                </Box>
                
            </CardAdjust>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropComponent={Backdrop}
                >
                <Box sx={style}>
                    <IframeAdjust src={url.replace('@thedownballot.org/video', 'embed/v2')} title={url} scrolling="no" />
                </Box>
            </Modal>

        </>

        
    )
}

StoryCard.propTypes = {
    image: PropTypes.string.isRequired,    
    url: PropTypes.string.isRequired,
    noMargin: PropTypes.bool.isRequired
    // title: PropTypes.string.isRequired,
}
