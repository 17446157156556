import React, { createContext, useContext, useReducer } from "react";
import PropTypes from 'prop-types';

export const StateContext = createContext();

export default function StateProvider({ reducer, initialState, children }) {
  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  )
};

StateProvider.propTypes = {    
  reducer: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  initialState: PropTypes.shape({
    term: PropTypes.string,
  }).isRequired
}

export const useStateValue = () => useContext(StateContext);
