import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types'

export default function ProtectedRoute({ role }) {   
    console.log('protectedroute', role);
    
    if (role === 'admin') {
        return <Outlet />;
    }

    return <Navigate to="/login" replace />;

    
};

ProtectedRoute.propTypes = {
    role: PropTypes.string.isRequired    
}