import React from 'react'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import SearchField from './SearchField'


export default function Header2() {
    return (
        <Box p={2}>
            <Grid container direction="column">                
                <Grid item sx={{mb: 4}}>
                    <Link href="/" variant="h1" underline="none" color="primary">
                        Library
                    </Link>
                </Grid>
                <Grid item sx={{mb: 2}}>
                    <Button variant="contained" href="/learninglib" sx={{mr:3}}>By Category</Button>
                    <Button variant="contained" href="/learninglibnewfirst">Newest First</Button>
                </Grid>
                <Grid item>
                    <SearchField />
                </Grid>
                
            </Grid>
        </Box>
    )
}
