import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined'
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined'
import StoryCard from './StoryCard'

const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0
    const slideTimer = setInterval(() => {
        const elementObj = element
        elementObj.scrollLeft += step // px
        scrollAmount += Math.abs(step)
        if (scrollAmount >= distance) {
            clearInterval(slideTimer)
        }
    }, speed)
}

// TODO: arrows not updating to disabled
export default function HorizontalStoryContainer(props) {
    const { title, stories } = props
    const contentWrapper = useRef(null)

    return (
        <Container>
            <Box
                component="div"
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography variant="h2" sx={{maxWidth: { xs: '50%', md: '70%'}, mt: 3, mb: 3}} color="primary">{title}</Typography>
                <Box component="div">
                    <IconButton
                        onClick={() => {
                            sideScroll(contentWrapper.current, 25, 240, -20)
                        }}
                    >
                        <ArrowCircleLeftOutlinedIcon
                            fontSize="large"
                            sx={{ color: 'secondary.main' }}
                        />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            sideScroll(contentWrapper.current, 25, 240, 20)
                        }}
                    >
                        <ArrowCircleRightOutlinedIcon
                            fontSize="large"
                            sx={{ color: 'secondary.main' }}
                        />
                    </IconButton>
                </Box>
            </Box>
            <Box
                component="div"
                ref={contentWrapper}
                sx={{ display: 'flex', overflow: 'hidden', width: '100%' }}
            >
                {/* <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>             */}
                {stories.map((story) => (
                    <StoryCard
                        /* eslint-disable-next-line no-underscore-dangle */
                        key={story._id}
                        /* eslint-disable-next-line no-underscore-dangle */
                        itemId={story._id} // NOTE: itemId is required for track items
                        /* eslint-disable-next-line react/jsx-props-no-spreading */                        
                        image={story.thumbnail_url}
                        url={story.url}
                        noMargin={false}
                    />
                ))}
                {/* </ScrollMenu> */}
            </Box>
        </Container>
    )
}

HorizontalStoryContainer.propTypes = {
    title: PropTypes.string.isRequired,
    stories: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        thumbnail_url: PropTypes.string.isRequired
})).isRequired    
}
