import React, {useState, useEffect} from 'react';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export default function Scroll({showBelow}) {

    const [show, setShow] = useState(!showBelow);

    const handleScroll = () => {
        setShow(!show);
    }

    const handleClick = () => {
        window.scrollTo({ top: 20, behavior: 'smooth'});
    }

    const buttonStyle = {                
        zIndex: 2, 
        backgroundColor: "#C86734", 
        color: "white", 
        "&:hover": {
            backgroundColor: "#C86734", 
            opacity: 0.7
        }
    }

    useEffect( () => {
        if (showBelow) {
            window.addEventListener('scroll', handleScroll);            
        }
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <Box sx={{mt: 3, display: 'flex', justifyContent: 'end'}}>
            { show && 
            <div>
            <IconButton onClick={handleClick} sx={buttonStyle}>
                <ExpandLessOutlinedIcon />
            </IconButton>
            <Typography>Back to Top</Typography>
            </div>
            }
        </Box>
    )
}

Scroll.propTypes = {
    showBelow: PropTypes.number.isRequired        
}
