import React, {useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../images/DownballotLogo';
import LogoutMenu from './LogoutMenu';



export default function ResponsiveAppBar({role, handleRole}) {
  // const [anchorElNav, setAnchorElNav] = useState(null); 
  const [anchorEl, setAnchorEl] = useState(null);
  // const [anchorMore, setAnchorMore] = useState(null);
  const open = Boolean(anchorEl);
  // const openMore = Boolean(anchorMore);
  const pages = ['Home', 'About', 'Library'];  
  const links = ['/', '/about', '/learninglib'];
  
  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };  

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };    
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /*
  const handleClickMore = (event) => {
    setAnchorMore(event.currentTarget);
  }
    */

  /*
  const handleCloseMore = () => {
    setAnchorMore(null)
  }
    */

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" color="background" elevation="0" sx={{p: 2}}>
      <Container maxWidth="xl">
        <Toolbar component="div" disableGutters sx={{display: 'flex', justifyContent:'space-between'}}>
          <Box component="div" sx={{width: {xs: '30%', md: '15%'}, maxWidth: '100px'}}><Link href="/"><Logo /></Link></Box>                      
          
          {/* <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"              
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"              
            >
              <MenuIcon sx={{fontSize: "2.5rem"}} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page, index) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Link underline="none" textAlign="center" href={links[index]}>{page}</Link>
                </MenuItem>
              ))}
              <MenuItem key='voterchecklist' onClick={handleCloseNavMenu}>
                <Link underline="none" textAlign="center" href="/voter-checklist">Voter Checklist</Link>
              </MenuItem>
              { role === "admin" && 
              <>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link underline="none" textAlign="center" href='/admin/library'>Admin - Library</Link>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link underline="none" textAlign="center" href='/admin/candidate'>Admin - Candidate</Link>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link underline="none" textAlign="center" href='/admin/feedback'>Admin - Feedback</Link>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link underline="none" textAlign="center" href='/admin/search'>Admin - Google Search</Link>
                </MenuItem>
                <LogoutMenu handleRole={handleRole} />
              </>}
            </Menu>
          </Box> */}
          
          <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
            {pages.map((page, index) => (
              <Button
                key={page}
                href={links[index]}
                sx={{ mx: 1, color: 'secondary.main', display: 'block', borderRadius: '0', borderTop: '1px solid black' }}
              >
                {page}
              </Button>
            ))}
            {/* 
            <div>
              <Button
                id="basic-button-more"
                href="/voter-checklist"
                aria-controls={openMore ? 'basic-menu-more' : undefined}
                aria-haspopup="true"
                aria-expanded={openMore ? 'true' : undefined}
                onClick={handleClickMore}
                sx={{ mx: 1, color: 'secondary.main', display: 'block', borderRadius: '0', borderTop: '1px solid black' }}
              >
                Voter Checklist
              </Button>
            </div>
            */}

            { role === 'admin' && 
              <div>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ mx: 1, color: 'secondary.main', display: 'block', borderRadius: '0', borderTop: '1px solid black' }}
              >
                Admin
              </Button>
              <Menu
                id="basic-menu"                
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleClose}>
                  <Link underline="none" textAlign="center" href="/admin/library">Library</Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link underline="none" textAlign="center" href="/admin/candidate">Candidate</Link>
                </MenuItem>        
                <MenuItem onClick={handleClose}>
                  <Link underline="none" textAlign="center" href="/admin/feedback">Feedback</Link>
                </MenuItem> 
                <MenuItem onClick={handleClose}>
                  <Link underline="none" textAlign="center" href="/admin/search">Google Search</Link>
                </MenuItem> 
                <LogoutMenu handleRole={handleRole} />
              </Menu>
            </div>
            
            }
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

ResponsiveAppBar.propTypes = {
  role: PropTypes.string.isRequired,
  handleRole: PropTypes.func.isRequired
}
