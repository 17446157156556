import React         from 'react';
import TilePage      from "../components/TilePage";

const MISSION = {
  tileTitle: "Mission",
  popupTitle: "Our Mission",
  paragraphTexts: [
    `This project is committed to providing simplified coverage for the` +
    ` down-ballot races that will take place in the next four years` +
    ` leading up to the next presidential race in 2024. Our goal is to` +
    ` be a one-stop-shop that provides progressive constituents with` +
    ` the resources to make informed decisions on who best represents` +
    ` their core values.`,
 
    `In addition, we aim to help voters connect the dots between a` +
    ` politician's stance on key issues and the impact that their` +
    ` policy positions will have on various sectors of society.` +
    ` Moreover, we are committed to highlighting cases in which` +
    ` politicians hold inconsistent political messaging such as` +
    ` claiming to be a champion of the working class while` +
    ` simultaneously voting against legislation that grants employees` +
    ` better working conditions and wages.`,

    `Ultimately, it's not enough to simply provide information.` +
    ` Contextualizing policies within historical, racial, and` +
    ` socio-economic frameworks is a critical to furthering` +
    ` political education.`,

    `Lastly, it is important to note that we do not adhere to a` +
    ` 'neutral' view of politics. This is because the policies` +
    ` advocated by various candidates will have a real effect on` +
    ` the material conditions of countless people. Thus, thedownballot` +
    ` is an explicitly progressive platform that exists for the express` +
    ` purpose of ushering in progressive policies that will allow the` +
    ` proletariat to have access to  healthcare and reproductive` +
    ` rights, worker rights, environmental protections/regulations,` +
    ` racial justice, and more!`
  ]
};
const PROBLEM = {
  tileTitle: "Problem",
  popupTitle: "The Problem",
  paragraphTexts: [
    `Hello! Thank you for your interest in getting to know a little` +
    ` more about us!`,

    `As you may know, it is difficult to consistently engage with the` +
    ` electoral process. There's a lot to learn and keep track of on a` +
    ` routine basis in order to understand what is taking place at all` +
    ` times - a task that is much more difficult amidst a pandemic and` +
    ` a political climate that is as contentious as ever.`,

    `While 2020 was undoubtedly a year of heightened political` +
    ` involvement for many, the work continues. Between today and the` +
    ` next presidential election in 2024, there will be myriad other` +
    ` political races, such as the 2022 midterms, that are often` +
    ` forgotten. And that is the world of the 'down-ballot'.`,

    `Downballot races are contests for state and local offices such as` +
    ` seats within the state legislature or the city council. However,` +
    ` these elections are often not widely publicized and difficult to` +
    ` track. Thus, elections for down-ballot offices take place without` +
    ` the input of numerous constituents. In fact, according to` +
    ` nationalcivicleague.org, "affluent voters have 30-50% higher` +
    ` turnout in local elections than low-income voters. Those 65+ are` +
    ` seven times more likely to vote in local elections than voters` +
    ` aged 18 to 34. Whites vote at rates 20% higher in local elections` +
    ` than non-white voters." Thus, local legislation is largely shaped` +
    ` by older, white, and affluent constituents who are seven times more` +
    ` likely to vote in local elections than voters aged 18 to 34. This` +
    ` demographic is also often in favor of small government and austerity` +
    ` policies. In contrast, 70% of Gen Z and 64% of Millennial voters` +
    ` believe that the government has a large role to play in solving` +
    ` socio-economic issues.`
  ]
};
const PROPOSAL = {
  tileTitle: "Proposal",
  popupTitle: "Our Proposal",
  paragraphTexts: [
    `The fact that older white conservatives disproportionately skew the` +
    ` results of local elections is deeply concerning. This is because` +
    ` state and local officials will ultimately pass legislation that` +
    ` directly affects many of the same voters who are left out of the` +
    ` electoral process.`,

    `For example, members of the state legislature determine how budgets` +
    ` will be allocated (how much is going to the police, roads, parks,` +
    ` etc.), how extensive worker protections are (such as the right to` +
    ` unionize and make a living wage), which public institutions (such` +
    ` as education and infrastructure) will receive funding, and how` +
    ` access to healthcare (inluding reproductive and mental healthcare)` +
    ` will be distributed.`,

    `While the ability to organize and take political action in the` +
    ` streets is one of the most powerful rights that constituents can` +
    ` exercise, we believe that protests are greatly complemented and` +
    ` accompanied by political action at the voting booth. This is` +
    ` especially true in light of the reality that the goal of most` +
    ` protests is to pressure politicians into passing certain` +
    ` legislation. So why not take it a step further to ensure that` +
    ` politicians who are sympathetic to the demands of their` +
    ` constituents are part of the legislative process?`,

    `For example, we can fight for greater police accountability by` +
    ` unseating district attorneys who refuse to prosecute police` +
    ` officers who have committed acts of brutality, and electing` +
    ` representatives who will work to reallocate oversized police` +
    ` budgets toward social programs and pass legislation that turns` +
    ` demands for justice into law.  At the downballot, we aim to bridge` +
    ` the gap between philosophy and praxis by empowering voters to put` +
    ` progressive politics into practice through consistent engagement` +
    ` with state and local elections.`,

    `This project is committed to providing simplified coverage for the` +
    ` down-ballot races that will take place in the next four years` +
    ` leading up to the next presidential race in 2024. Our goal is to be` +
    ` a one-stop-shop that provides progressive constituents with the` +
    ` resources to make informed decisions on who best represents their` +
    ` core values.`,

    `In addition, we aim to help voters connect the dots between a` +
    ` politician's stance on key issues and the impact that their policy` +
    ` positions will have on various sectors of society. Moreover, we are` +
    ` committed to highlighting cases in which politicians hold` +
    ` inconsistent political messaging such as claiming to be a champion` +
    ` of the working class while simultaneously voting against legislation` +
    ` that grants employees better working conditions and wages.`,

    `Ultimately, it's not enough to simply provide information.` +
    ` Contextualizing policies within historical, racial, and` +
    ` socio-economic frameworks is a critical to furthering political` +
    ` education.`,

    `Lastly, it is important to note that we do not adhere to a 'neutral'` +
    ` view of politics. This is because the policies advocated by various` +
    ` candidates will have a real effect on the material conditions of` +
    ` countless people. Thus, thedownballot is an explicitly progressive` +
    ` platform that exists for the express purpose of ushering in` +
    ` progressive policies that will allow the proletariat to have access` +
    ` to  healthcare and reproductive rights, worker rights, environmental` +
    ` protections/regulations, racial justice, and more!`
  ]
};
const APPROACH = {
  tileTitle: "Approach",
  popupTitle: "Our Approach",
  paragraphTexts: [
    `This project was put together by a team of resourceful individuals` +
    ` who are committed to growing our website into a platform that` +
    ` covers local elections across the United States. It is also a` +
    ` launching pad that we hope to use to mobilize engineers,` +
    ` researchers, voters, and more to join us in working toward our` +
    ` mission.`, 

    `The information included in our coverage of the midterm elections is` +
    ` based on candidate interviews, campaign website endorsements,` +
    ` publicized sources of campaign funds, and candidate voting history.` +
    ` There are sources listed in each candidate profile so that users` +
    ` know exactly where the information we gathered came from.`, 

    `Below is a general summary of the key websites we used to gather` +
    ` the verifiable information that powers our platform:`,

    `Open Secrets | Ballotpedia | My Reps Common Cause`,

    `Who is My Representative | USA.gov | govtrack | Vote Smart`,

    `Transparencyusa | Five Thirty Eight | Bill Track 50`, 

    `If we got anything wrong, we highly encourage you to fact check us!` +
    ` In addition, we welcome all feedback on any improvements that` +
    ` should be made to the platform as we grow.`
  ]
};
const CURRENT_FOCUS = {
  tileTitle:  "Midterms 2022",
  popupTitle: "2022 Midterms",
  paragraphTexts: [
    `As a young organization, we began our work by releasing three` +
    ` MVPs (minimum viable product) of our platform: the first covered` +
    ` Wisconsin's downballot candidates during the November 3rd election` +
    ` in 2020, the second highlighted the Georgia Runoff that came right` +
    ` after, and the third was centered around the New York City Council` +
    ` Race.`,

    `Our current focus is the 2022 midterms, within which many key` +
    ` battleground down-ballot elections will have a lasting impact on` +
    ` United States politics for the next couple of years. If you've been` +
    ` wanting to get involved in your local politics, this is your time to` +
    ` shine!`,
 
    `The election will be separated into two events - the primaries in` +
    ` May and June as well as the General Election in November. Specific` +
    ` dates will differ depending on your state.`,

    `There are numerous progressive candidates running this year and we` +
    ` encourage you to utilize our topic matrix within each candidate's` +
    ` page to understand where they stand on various important topics` +
    ` that are at stake during this election cycle.`,

    `If healthcare access for trans children matters to you, if` +
    ` affordable housing - especially during the pandemic - is a key` +
    ` concern for your community, if you want to have a say on worker's` +
    ` rights, racial justice, the protection of reproductive rights, and` +
    ` tax increases for the wealthy, voting in this year's midterm` +
    ` elections will be an opportunity to make your voice heard!`
  ]
};
const TILE_SPECS_BY_NAME =
  {MISSION, PROBLEM, PROPOSAL, APPROACH, CURRENT_FOCUS};

const servicesToChildren = {
  tileTitleForName:      (_verb, name) => TILE_SPECS_BY_NAME[name].tileTitle,
  expansionTitleForName: (_verb, name) => TILE_SPECS_BY_NAME[name].popupTitle,
  serializableDetailsBodyForName: (_verb, name) => {
    const badOldFmt = TILE_SPECS_BY_NAME[name].paragraphTexts;
    const mapped = badOldFmt.map(txt => ['p', txt]);
      /* Poof! Now, you're an official paragraph. */
    return ['div', ...mapped]
  },
  overarchingIdeaName: () => 'MISSION',
  subordinateIdeaNamesInOrder:
                    () => ['PROBLEM', 'PROPOSAL', 'APPROACH', 'CURRENT_FOCUS']
};
const self = (verb, ...args) => {
  const hit = servicesToChildren[verb];
  if (! hit) throw new Error(
    `AboutPage: unknown verb ${JSON.stringify(verb)}!`
  );
  return hit(verb, ...args)
};

const AboutPage = function AboutPage () {
  return <TilePage parent={self} titleText="About"
    subtitleText="Click on each tile to learn more about us!"
  />
};

export default AboutPage

