import React, { useState } from "react";
import "./Search.css";
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import MicIcon from '@mui/icons-material/Mic';
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../../../utils/StateProvider";
import { actionTypes } from "../../../utils/googleSearchReducer";

function SearchComponent({ hideButtons = false }) {
  const [_, dispatch] = useStateValue();
  const [input, setInput] = useState("");
  const navigate = useNavigate();

  const search = (e) => {
    e.preventDefault();
    console.log(_);
    console.log("button clicked", input);
    dispatch({
      type: actionTypes.SET_SEARCH_TERM,
      term: input,
    });
    navigate("/admin/searchResults");
  };

  return (
    <form className="search">
      <div className="search__input">
        <SearchIcon className="search__inputIcon" />
        <input value={input} onChange={(e) => setInput(e.target.value)} />
        <MicIcon />
      </div>
      {!hideButtons ? (
        <div>
          <Button onClick={search} variant="contained" color="info" sx={{marginTop: 2}}>
            Google Search
          </Button>          
        </div>
      ) : (
        <div className="search__buttons">
          <Button
            className="search__buttonsHidden"
            onClick={search}            
            variant="outlined"
          >
            Google Search
          </Button>          
        </div>
      )}
    </form>
  );
}

export default SearchComponent;

SearchComponent.propTypes = {
  hideButtons: PropTypes.bool.isRequired
}