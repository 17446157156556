import React, {useState, useEffect} from 'react';
import {
    createTheme,
    ThemeProvider,
    styled
} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
 // import Link from '@mui/material/Link';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import BillTracker from '../BillTracker';

const TableCellCustom = styled(TableCell)(() => ({
    borderRight: '1px solid black',
    textAlign: 'center',    
    color: "#384747",
    fontFamily: "'Didact Gothic', 'sans-serif'",
    '&:last-child': { borderRight: 0 }
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  transform: 'translate(-50%, -50%)',
  width: {xs: "80%", md: "60%"},
  height: "80%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll'
};

const tableStyle = {
    minWidth: 300     
};

const colorTheme = createTheme({    
    palette: {
      primary: {
        main: "#A4B7A5"
      },       
    },
    typography: {        
        body1: {
          color: "#384747"         
        },
        caption: {
          color: "#384747"
        }
      },
  });

export default function BillCategory({topic, candidateParam}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [billDetails, setBillDetails] = useState([]);
  const [billVote, setBillVote] = useState([]);
  const [billTracker, setBillTracker] = useState();
  const headings = ['BILL', 'DESCRIPTION', 'VOTED FOR', 'OUTCOME'];
  
  
  const getBillResults = async () => {
    if (candidateParam.office === "House of Representatives" || candidateParam.office === "Senate") {
        const topicEdit = topic.includes('Gov') ? topic.replace('Gov', 'Government') : topic;
        const response = await fetch(`/api/bill/info?category=${topicEdit}&firstName=${candidateParam.firstName}&lastName=${candidateParam.lastName}&typeOfElection=${candidateParam.office}`);
        const data = await response.json();        

        if(data.success) {
            setBillDetails(data.billResults);
            setBillVote(data.voteResults);
            setLoading(false);
        } else {
            console.log('error while fetching');
            setLoading(false);
        }
    } 
  }

  const voteColor = (vote) => {
    if (vote === 'Yea') {
        return '#124A0C';
    } 
    return '#BD000E';
   
  }

  const billColor = (yeaTotal, nayTotal) => {
      const result = yeaTotal - nayTotal;
      if (result >= 0) {
          return '#124A0C';
      } 
      return '#BD000E';
     
  }

  useEffect( () => {
      if (open) {        
        getBillResults();
      }
  }, [open]);

  const handleTracker = (url) => {
    setBillTracker(url);
  }

  return (
    <Box component="div" m='5px' sx={{ width: { xs: "100%", sm: "40%", md: "25%", lg: "20%"} }}>
      <ThemeProvider theme={colorTheme}>
        <Button variant="contained" sx={{ width: "100%" }} onClick={handleOpen}>{topic}</Button>
      </ThemeProvider>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h2" component="h2" color="primary">
                {topic}
            </Typography>                              
            <Box component="div" width="100%" sx={{marginTop: '10px', borderTop: '1px solid black'}}>
            <Typography align="center" variant="h4" my={3} component="h4">Voting History</Typography>
            { loading && 
                <Box component="div" sx={{ display: 'flex', gap: 1}}>
                    <CircularProgress />
                    <Typography my={2}>Loading Data.....</Typography>
                </Box>
            }
            { billTracker && <BillTracker url={billTracker} /> }
            <TableContainer component={Paper} sx={{border: '1px solid black', borderRadius: '20px'}}>
                
                
                <Table sx={tableStyle} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{borderBottom: '2px solid black'}}>
                            { headings && headings.map(heading => (
                                <TableCellCustom sx={{fontWeight: 'bold'}}>{heading}</TableCellCustom>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{'&:last-child tr': { borderBottom: 0 }}}>
                    {billDetails.length > 0 && billDetails.map((bill, index) => (
                        <TableRow
                        key={bill.docId}
                        sx={{ '&:last-child td': { borderBottom: 0 }, borderBottom: '2px solid black' }}
                        >
                            <TableCellCustom><Button onClick={() => handleTracker(bill.tracker)}>{bill.title}</Button></TableCellCustom>
                            <TableCellCustom sx={{textAlign: 'left'}}>{bill.description}</TableCellCustom>
                            <TableCellCustom><Box component="span" sx={{ color: voteColor(billVote[index]) }}>{billVote[index]?.toUpperCase()}</Box></TableCellCustom>
                            <TableCellCustom><Box component="span" sx={{ color: billColor(bill.yeaTotal, bill.nayTotal) }}>{bill.yeaTotal} - {bill.nayTotal}</Box></TableCellCustom>
                                                                                                       
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
        </Box>
      </Modal>
    </Box>
  );
}

BillCategory.propTypes = {
    topic: PropTypes.string.isRequired,
    candidateParam: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        district: PropTypes.string.isRequired,
        office: PropTypes.string.isRequired
    }).isRequired
}