import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import parse from 'html-react-parser';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import VotingHistory from '../components/VotingHistory';
import CandidateProfile from '../components/CandidateProfile';
import Sponsorship from '../components/Sponsorship';
import ImportantIssuesAcc from '../components/ImportantIssuesAcc';
import Sources from '../components/Sources';

const Item = styled(Box)(({ theme }) => ({    
    display: 'flex',
    justifyContent: 'center',
    ...theme.typography.body2,
    padding: theme.spacing(1),    
    color: theme.palette.text.secondary,
  }));

export default function CandidateProfilePage() {
    
   
    const {id} = useParams();
    
    // const [searchParams] = useSearchParams();
    const [candidate, setCandidate] = useState();  
    const[questionVisible, setQuestionVisible] = useState(false)
    const dividerStyle = {
        borderBottomWidth: 5,
        borderColor: '#C3B887'
    };  
    const [candidateIDs, setCandidateIDs] = useState([]);
    const [backUrl, setBackUrl] = useState('#');

    const getCandidateInfo = async() => {     
        
        try {
            const response = await fetch(`/api/newCandidate/${id}`);
            const data = await response.json();        
            setCandidate(data.data);
        } catch (err) {
            console.log(err);
        } 
    }

    useEffect(() => {   
        getCandidateInfo();      
        const {searchString: searchUrl, candidateIds: candidateIdList} = JSON.parse(window.localStorage.getItem('candidateResult'));
        if(candidateIdList && searchUrl) {
            setCandidateIDs(candidateIdList);
            setBackUrl(searchUrl);
        }
    }, []);

    useEffect(()=>{
        if(!candidate) return
        setQuestionVisible(['Senate', 'House of Representatives', 'State HOR', 'State Senate', 'State Assembly'].includes(candidate.office))
    },[candidate])


    return (
        
        <Container sx={{ p: 2, minHeight: '100vh', width: "80%"}}>
            <Box component="div" sx={{display: 'flex', gap: 2, justifyContent: 'space-between'}}>
                <IconButton href={`/${backUrl}`} disabled={backUrl === '#' || candidateIDs.findIndex( oneId => oneId === id) === -1}>
                    <ArrowBackIcon /> <Typography>Search results</Typography>
                </IconButton>      
                { candidateIDs.findIndex( oneId => oneId === id) > -1 &&          
                <Box component='div' sx={{ display: 'flex', gap: 1}}>
                    <IconButton 
                        href={ candidateIDs.findIndex( oneId => oneId === id) === 0 ? '#' : `/candidatePage/${candidateIDs[candidateIDs.findIndex( oneId => oneId === id) - 1]}`}
                        disabled={ candidateIDs.findIndex( oneId => oneId === id) === 0}
                    >
                        <ArrowCircleLeftIcon /> 
                    </IconButton>   
                    <IconButton 
                        href={ candidateIDs.findIndex( oneId => oneId === id) === candidateIDs.length - 1 ? '#' : `/candidatePage/${candidateIDs[candidateIDs.findIndex( oneId => oneId === id) + 1]}`}
                        disabled={ candidateIDs.findIndex( oneId => oneId === id) === candidateIDs.length - 1}
                    >
                        <ArrowCircleRightIcon />
                    </IconButton>   
                </Box>
                }
            </Box>
            <Typography variant="h1" mb={2} color='primary'>General Election 2024</Typography>
            
            
            <Grid container spacing={2} my={2}>
                <Grid item xs={12} md={questionVisible ? 6 : 12}>
                    <Item>
                        { candidate && <CandidateProfile candidate={candidate} candidatePage />}
                    </Item>
                    { candidate && candidate.specialNotes && 
                    
                    <Box sx={{ marginLeft:"auto", marginRight:"auto"}}>

                        <Box sx={{ my: 2, py: 1, borderRadius: '5px', backgroundColor: '#A0B9A5'} }>
                            <Typography>Analysis</Typography>
                        </Box>
                        <Box sx={{ textAlign: 'left'}}>
                            {/* <Typography variant="body1" align='left'>{candidate.specialNotes}</Typography> */}
                            { candidate.specialNotes[0] === "<" ? parse(candidate.specialNotes) : 
                             <Typography variant="body1" align='left'>{candidate.specialNotes}</Typography> }
                        </Box>
                    </Box>
                    
                    }
                </Grid>
                {candidate && questionVisible &&
                    <Grid item xs={12} md={6}>
                    { candidate && <ImportantIssuesAcc matrixQuestions={candidate.matrixQuestions} office={candidate.office} /> }
                    </Grid>
                }
            </Grid>          
            <Divider variant='middle' sx={dividerStyle} />  
            
            { (candidate?.office === "House of Representatives" || candidate?.office === "Senate") && candidate.incumbent === "Incumbent" && 
            <>
                <VotingHistory candidateParam={candidate} /> 
                <Divider variant='middle' sx={dividerStyle} />  
                <Sponsorship candidateParam={candidate} />
            </>
            }
            <Divider variant='middle' sx={dividerStyle} />  
            { candidate && <Sources sources={candidate.sources} /> }
            
            
        </Container>
        
    )
}

