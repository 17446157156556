import React from 'react';
import PropTypes from 'prop-types'
// import { styled } from '@mui/material/styles'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import categories from '../utils/categories';
// import InputBase from '@mui/material/InputBase';

export default function CatDropDown({chosenCategory, setPageNumber, setChosenCategory}) {
    
    const handleChange = (event) => {
        setChosenCategory(event.target.value);
        setPageNumber(1);
    };

    return (
        <FormControl variant="filled" sx={{width: {xs: "80%", md: "60%"}, mb: 4}}>
            <InputLabel id="demo-simple-select-label" sx={{color: 'white'}}>Book Categories</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select-label"
                value={chosenCategory}
                label="Book Categories"
                onChange={handleChange}                
                sx={{
                    backgroundColor: "background.dark", 
                    padding: '0', 
                    border:'1px solid #384747', 
                    borderRadius: '5px', 
                    "& .MuiSelect-select": {
                        color: 'white'
                    },
                    "& .MuiSelect-select:focus": {
                        color: 'black'
                    },
                    "& .MuiSelect-select:hover": {
                        color: 'black'
                    },
                    "&:after" : {
                        borderBottom: '1px solid black'
                    },
                    "&:hover": {
                        color: 'black'
                    }                    
                }}
            >   
                <MenuItem value='' key='all'>All videos</MenuItem>
                { categories.map( (category) => (
                    <MenuItem value={category} key={category}>{category}</MenuItem>
                ))}                
            </Select>
        </FormControl>
    )
}


CatDropDown.propTypes = {
    chosenCategory: PropTypes.string.isRequired,
    setChosenCategory: PropTypes.func.isRequired,    
    setPageNumber: PropTypes.func.isRequired,  
    // title: PropTypes.string.isRequired,
}