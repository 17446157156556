import React, { useState, useRef, useCallback } from 'react';
import Box from '@mui/material/Box'
// import HomeBanner from '../components/HomeBanner'
import Page from '../components/Page'
import HorizontalStoryContainer from '../components/HorizontalStoryContainer'
import CatDropDown from '../components/CatDropDown'
import loadMoreVideos from '../hooks/loadMoreVideos'
import Scroll from '../components/Scroll'

export default function Homepage() {
    const [chosenCategory, setChosenCategory] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const { loading, videos, sections, hasMore, categoryVideos, categorySection } = loadMoreVideos(pageNumber, chosenCategory);
    const observer = useRef();
    const lastSectionRef = useCallback( node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if(entries[0].isIntersecting && hasMore) {
                console.log('Visible');
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    // const loadMoreVideos = async (page) => {             
    //     const result = await axios.get(`/api/books/page/${page}`);
    //     if (result.status !== 200) {
    //         throw new Error('Error on server side');        
    //     } else {
    //         const newData = result.data.data;
    //         setData( prevData => [...prevData, ...newData ]);
    //         const temp = result.data.data.map((video) => video.category);            
    //         const newCategories = [...new Set(temp)];
    //         setSections( prevSections => [...prevSections, ...newCategories]);
    //     }
    // };

    return (
        <Page>
            <CatDropDown chosenCategory={chosenCategory} setPageNumber={setPageNumber} setChosenCategory={setChosenCategory} />
            {/* <HomeBanner imageURL="https://artlogic-res.cloudinary.com/w_1200,h_900,c_limit,f_auto,fl_lossy/ws-michaelhoppen/usr/images/artworks/main_image/10108/ravens020-s-.jpg" /> */}
            {sections.length > 0 && sections.map((section, index) => {
                const temp = videos.filter((video) => video.category === section)
                if (sections.length === index+1) {
                    return (
                        <Box component='div' ref={lastSectionRef} mb={2}>
                            <HorizontalStoryContainer key={section} title={section} stories={temp} />
                            <Scroll showBelow={200} />
                        </Box>
                        
                    )
                } 
                
                return (
                    <Box component='div' mb={2}>
                        <HorizontalStoryContainer key={section} title={section} stories={temp} />
                    </Box>
                )   
                
                
            })}
            {loading && <Box component="div">Loading......</Box>}
            {categorySection !== '' && 
                (<Box component='div' mb={2}>
                    <HorizontalStoryContainer key={categorySection} title={categorySection} stories={categoryVideos} />
                </Box>
                )
            }            
            
        </Page>
    )
}
