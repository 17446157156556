import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

export default function Countdown({countdownDate, countdownSubject}){
    const [formattedCountdown, setFormattedCountdown] = useState('00 00 00 00')
    const [isTimerOver, setIsTimerOver] = useState(false)
    const getTimeRemaining = () => {
        const total = Date.parse(countdownDate) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        const days = Math.floor((total/1000/3600/24))
        return {
            total, hours, minutes, seconds, days
        };
    }

    const startTimer = () => {
        const { total, hours, minutes, seconds, days } 
                    = getTimeRemaining();
        if (total >= 0) {
  
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setFormattedCountdown(`
                ${days > 9 ? days : `0${days}`} ${hours > 9 ? hours : `0${hours}`} ${minutes > 9 ? minutes : `0${minutes}`} ${seconds > 9 ? seconds : `0${seconds}`}
            `)
            
        }
        else{
            setIsTimerOver(true)
        }
    }
  
    useEffect(() => {
        console.log(countdownDate.toDateString())
        const id = setInterval(() => {
            if(!isTimerOver){
                startTimer()
            }
            else{
                clearInterval(id)
            }
        })
        return () => clearInterval(id)
    }, [])

    return(
        <Box mb={2}>
            <Typography color="white" variant="h2" sx={{fontSize: {xs: '10px', sm: '16px'}}}>
                {`${countdownDate.toDateString().substring(4, 10).toUpperCase()} | ${countdownSubject.toUpperCase()}`}
            </Typography>
            <Box sx={{display: "flex", flexDirection: "row", justifyContent: 'center'}}>
                <CountdownDigit countdownNumber={formattedCountdown.substring(17, 19)} unitOfTime="Days"/>
                <CountdownDigit countdownNumber={formattedCountdown.substring(20, 22)} unitOfTime="Hours"/>
                <CountdownDigit countdownNumber={formattedCountdown.substring(23, 25)} unitOfTime="Mins" />
                <CountdownDigit countdownNumber={formattedCountdown.substring(26, 28)} unitOfTime="Secs"/>
            </Box>
        </Box>
    )
}

function CountdownDigit({countdownNumber, unitOfTime}){
    return (
        <Box sx={{display:"flex", flexDirection: "column", mx: 1}}>
            <Typography color="white" variant="h1" sx={{fontSize: {xs: '42px', sm: '46px'}}}>
                {countdownNumber}
            </Typography>
            <Typography color="white">
                {unitOfTime}
            </Typography>
        </Box>
    )
}

Countdown.propTypes = {
    countdownDate: PropTypes.instanceOf(Date).isRequired,
    countdownSubject: PropTypes.string
}

CountdownDigit.propTypes = {
    countdownNumber: PropTypes.string,
    unitOfTime: PropTypes.string,
}