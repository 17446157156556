import React from 'react'
import Box from '@mui/material/Box'
import { SocialIcon } from 'react-social-icons'
import Grid from '@mui/material/Grid'

export default function SocialIcons() {

    const styleSmall = window.innerWidth < 900 ? 
    { 
        height: "2rem",
        width: "2rem"             
    } : 
    { 
        height: "2.5rem",
        width: "2.5rem"             
    };

    return (
        <Box>
            <Grid container direction="horizontal">
                <Grid item xs>
                    <Box p={1}>
                        <SocialIcon url="https://www.instagram.com/thedownballot/" bgColor="#565656" style={styleSmall} />
                    </Box>
                </Grid>                
                <Grid item xs>
                    <Box p={1}>
                        <SocialIcon url="https://www.youtube.com/channel/UCbW-BJYIMo08unzxJxn2vWw" bgColor="#565656" style={styleSmall} />
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box p={1}>
                        <SocialIcon url="https://www.tiktok.com/@thedownballot.org" bgColor="#565656" style={styleSmall} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
