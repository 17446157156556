import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles'

export default function FeedbackPage() {
    const initialState = {
        fullName: '',
        email: '',
        feedback: '',
    }

    const [formInputs, setFormInputs] = useState(initialState)
    const [errors, setErrors] = useState({})
    const [showSuccessAlert, setShowSuccessAlert] = useState(false)
    const [showErrorAlert, setShowErrorAlert] = useState(false)

    const renderAlert = () => {
        if (!showSuccessAlert && !showErrorAlert) {
            return null
        }
        if (showErrorAlert) {
            return (
                <Alert severity="error">
                    <AlertTitle>There was an error</AlertTitle>
                </Alert>
            )
        }
        return (
            <Alert severity="success">
                <AlertTitle>
                    You have successfully submitted your feedback!
                </AlertTitle>
            </Alert>
        )
    }

    const findFormErrors = () => {
        const { fullName, email, feedback } = formInputs
        const newErrors = {}
        const verifyEmail = /^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i

        if (!fullName || fullName === '') {
            newErrors.fullName = 'cannot be blank!'
            newErrors.fullNameError = true
        }

        // email error
        if (!email || email === '') {
            newErrors.email = 'cannot be blank!'
            newErrors.emailError = true
        } else if (verifyEmail.test(email) === false) {
            newErrors.email = 'not a valid email address'
            newErrors.emailError = true
        }

        if (!feedback || feedback === '') {
            newErrors.feedback = 'cannot be blank!'
            newErrors.feedbackError = true
        }

        return newErrors
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setErrors({})
        // get our new errors
        const newErrors = findFormErrors()

        if (Object.keys(newErrors).length > 0) {
            // We've got errors on the front end
            setErrors(newErrors)
            setShowErrorAlert(true)
        } else {

            console.log('formInputs', formInputs);

            try {
                const res = await fetch('/api/feedback', {
                    method: 'POST',
                    headers: { 'content-type': 'application/json' },

                    body: JSON.stringify(formInputs)

                })
                const data = await res.json()
                if (data.success) {
                    console.log('success')
                    setShowSuccessAlert(true)
                }
                console.log(data)
            } catch (err) {
                console.log(err)
            }
        }
    }

    const handleInputChange = (e) => {
        const obj = { ...formInputs, [e.target.name]: e.target.value }
        setFormInputs(obj)
        setShowSuccessAlert(false)
        setShowErrorAlert(false)
    }

    const FormHelperTextStyled = styled(FormHelperText)(() => ({
        color: 'white'
    }));


    return (
        <Container
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: '100vh',
            }}
        >
            <Typography variant="h2" color="primary" mb={2}>
                Send Us Your Feedback!
            </Typography>
            {renderAlert()}
            <Box
                noValidate
                autoComplete="off"
                sx={{
                    p: 2,
                    border: '1px solid #C86734',
                    borderRadius: '5px',
                    width: "80%"

                }}
            >
                <Typography align="left" gutterBottom="true" mb={2}>
                    Enter Your Information Below:
                </Typography>
                <Box
                    sx={{
                        p: 2,
                        my: 2,
                        backgroundColor: '#C86734',
                        borderRadius: '5px',
                    }}
                >
                    
                    <Box sx={{ mb: 2 }}>

                        <Box component='div' mb={2}>
                        <TextField
                            variant="outlined"
                            placeholder="Full Name"
                            size="small"
                            name="fullName"
                            error={errors?.fullNameError}                            
                            onChange={handleInputChange}
                            fullWidth
                            sx={{
                                backgroundColor: 'white',
                                borderRadius: '5px'                                
                            }}
                        />
                        <FormHelperTextStyled>{errors.fullName}</FormHelperTextStyled>
                        </Box>
                        <Box component='div' mb={2}>
                        <TextField
                            variant="outlined"
                            placeholder="Email"
                            size="small"
                            name="email"
                            fullWidth                            
                            error={errors?.emailError}
                            onChange={handleInputChange}
                            sx={{
                                backgroundColor: 'white',
                                borderRadius: '5px'                                
                            }}
                        />
                        <FormHelperTextStyled>{errors.email}</FormHelperTextStyled>
                        </Box>
                        <Box component='div' mb={2}>
                        <TextField
                            variant="outlined"
                            placeholder="Enter Your Feedback Here"
                            size="small"
                            name="feedback"
                            fullWidth
                            multiline
                            helperText={errors.feedback}                            
                            onChange={handleInputChange}
                            minRows={10}
                            sx={{
                                backgroundColor: 'white',
                                borderRadius: '5px'
                            }}
                        />
                        <FormHelperTextStyled>{errors?.feedbackError}</FormHelperTextStyled>

                        </Box>
                    </Box>
                </Box>
                <Button variant="contained" onClick={handleSubmit}>
                    Submit
                </Button>
            </Box>
        </Container>
    )
}
