import React, {useState, useReducer} from 'react'
import axios from 'axios'
import Typography from '@mui/material/Typography'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
// import FormGroup from '@mui/material/FormGroup'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import reducer from '../utils/reducer';
import categories from '../utils/categories';
// import Container from '@mui/material/Container'


export default function AdminAddMore() {
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);
    const initialState = {};
    const [formInputs, dispatch] = useReducer(reducer, initialState);   
    

    const handleInputChange = (inputEvent) => {
        dispatch({
            type: "update",
            payload: {
                field: inputEvent.target.name,
                value: inputEvent.target.value
            }
        });   
    }

    const handleSubmit = async() => {
        
        const data = new FormData();
        data.append("url", `https://www.tiktok.com/@thedownballot.org/video/${formInputs.url}`);
        data.append("category", formInputs.category);
        data.append("image", file);

        setLoading(true);
        const response = await axios.post('/api/books', data);
        console.log('response.data', response.data);
        if (response.data.success) {
            setLoading(false);
            window.location.assign('/admin/library');         
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // const categories = [
    //     "As We Wait for the Revolution",
    //     "Conspiracy And Capitalism",
    //     "Deconstructing Narratives",
    //     "Dictatorship of the Proletariat",
    //     "Do Something About It",
    //     "I Dream of Socialism",
    //     "Neoliberalism Explained",
    //     "Racism, Pride And Prejudice",
    //     "Right Wing Rhetoric",
    //     "So You Want to be a Capitalist?",
    //     "Spoils of the Imperial Core",
    //     "The Fetishization of Nonviolence",
    //     "The Follies of Capitalism",
    //     "Unionizing 101",
    //     "Why Voting Matters"
    //   ];

    return (
        <Box component="div" sx={{p: 2}}>  
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                Add More
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add More Videos</DialogTitle>
                <DialogContent>
                    {/* <FormGroup sx={{border: '1px solid grey', borderRadius: '10px', p: 3}}> */}
                    
                        <FormControl variant="standard" sx={{ mb: 4 }}>
                            <InputLabel htmlFor="input-with-start-adornment" sx={{fontSize: '25px'}}>
                                URL
                            </InputLabel>
                            <Input
                            id="input-with-start-adornment"
                            type="number"
                            placeholder="videoId"
                            name="url"   
                            required
                            startAdornment={
                                <InputAdornment position="start">
                                    <Typography sx={{fontSize: {xs: '12px', sm: '18px'}}}>www.tiktok.com/@thedownballot.org/video/</Typography>
                                </InputAdornment>
                            }
                            value={formInputs.url}
                            onChange={handleInputChange}
                            />
                        </FormControl>
                    
                    
                        <FormControl sx={{ width: 300, mb: 4}}>
                            <InputLabel id="demo-simple-select-label">Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"                
                                label="Category"
                                name="category"    
                                autoWidth                              
                                required
                                value={formInputs.category}
                                onChange={handleInputChange}                      
                            >       
                                <MenuItem key='none' value='' />
                                {categories.map(category => (
                                    <MenuItem key={category} value={category}>{category}</MenuItem>
                                ))}                
                            </Select>
                        </FormControl>

                        <FormControl variant="standard" sx={{ width: 300, mb: 4 }}>                    
                            <Input                    
                            type="file"
                            accept="image/png, image/jpeg"
                            name="image"                    
                            required
                            onChange={(event) =>  setFile(event.target.files[0])}                    
                            />
                        </FormControl>
                        
                        
                        <DialogActions>
                            { loading ? <CircularProgress /> :
                            <Button color="primary" variant="contained" onClick={handleSubmit}>Submit</Button>
                            }
                        </DialogActions>
                        
                    {/* </FormGroup> */}
                </DialogContent>
            </Dialog>
        </Box>
        
    )
}

