import React from "react";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import "./GoogleSearchHome.css";
import SearchComponent from "./SearchComponent";
import ListKeywords from './ListKeywords';

function GoogleSearchHomePage() {
  return (
    <Container sx={{ p: 2, minHeight: '100vh', width: "90%"}}>      
      <div className="home__body">
        <Typography variant="h2">Key in the candidate name</Typography>
        <div className="home_inputContainer">
          <SearchComponent hideButtons={false} />
          <ListKeywords />
        </div>
      </div>
    </Container>
  );
}

export default GoogleSearchHomePage;
