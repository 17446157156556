import React, { useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import PropTypes from 'prop-types'
import DeleteIcon from '@mui/icons-material/Delete'
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

export default function AdminFeedbackPage({ role }) {
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const heading = ['Full Name', 'Email', 'Feedback'];

    const pages = new Array(numberOfPages).fill(null).map((v, i) => i);

    async function fetchFeedback() {
        try {
            setData([]);
            const res = await fetch(`/api/feedbackPagination?page=${pageNumber}`, {
                method: 'GET',
                headers: { 'content-type': 'application/json' },
            })
            const d = await res.json()
            if (d.success) {                
                setData(d.data.feedbacks);
                setNumberOfPages(d.data.totalPages);
            }
            
        } catch (err) {
            console.log(err)
        }
    }

    const handleDelete = async (id) => {
        try {
            const res = await fetch(`/api/feedback/${id}`, {
                method: 'DELETE',
                headers: { 'content-type': 'application/json' },
            })
            const d = await res.json()
            if (d.success) {
                console.log('success')
            }
            fetchFeedback()
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchFeedback()
    }, [pageNumber])

    if (role === 'user') {
        return (
            <Container
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minHeight: '100vh',
                }}
            >
                <Typography variant="h2" color="primary">
                    You have insufficient permissions to view this page.
                </Typography>
            </Container>
        )
    }
    return (
        <Container
            sx={{          
                width: '85%',      
                display: 'flex',
                flexDirection: 'column',                
                minHeight: '100vh',
            }}
        >
            <Typography variant="h2" color="primary" mb={4}>
                View Feedback Here!
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, flexWrap: 'wrap', mb: 2}}>
            {pages && pages.map( pageIndex => (
                <Button variant={ pageIndex === pageNumber ? 'contained' : 'text'} key={pageIndex} sx={{ width: '5%'}} onClick={() => setPageNumber(pageIndex)}>
                    {pageIndex + 1}
                </Button>
            ))}
            </Box>
            <Box                
                sx={{
                    width: '100%',                    
                    p: 1,
                    border: '1px solid #C86734',
                    borderRadius: '5px',   
                    mb: 1                                
                }}
            >
                <Grid container spacing={1} sx={{display: {xs: 'none', md: 'flex'}, mb: 1}}>
                    { heading.map( oneHeading => (
                    <Grid item md={ oneHeading === 'Full Name' ? 3 : 4}>
                        <Typography variant='body1' align='left' sx={{fontWeight: 'bold'}}>{oneHeading}</Typography>
                    </Grid>
                    ))}                    
                </Grid>
                {data && data.map((value, index) => (
                <Grid key={value.fullName} container spacing={1} sx={{mb: 1}}>       
                    <Grid item xs={12} md={3}>
                        <Box sx={{display: 'flex', gap: '2px'}}>
                            <Typography sx={{ display: {xs: 'block', md: 'none'}}}>From: </Typography>
                            <Typography variant='body1'>{value.fullName}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{display: 'flex', gap: '2px'}}>
                            <Typography sx={{ display: {xs: 'block', md: 'none'}}}>Email: </Typography>
                            <Typography variant='body1'>{value.email}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant='body1' align='left'>{value.feedback}</Typography>                        
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <IconButton onClick={() => handleDelete(value._id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    { index !== data.length-1 && 
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    }
                </Grid>
                ))}
            </Box>
            
        </Container>
    )
}

AdminFeedbackPage.propTypes = {
    role: PropTypes.string.isRequired,
}
