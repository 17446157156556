import React, {useState, useReducer, useEffect} from 'react';
import PropTypes from 'prop-types'
import { useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import ReactGA from 'react-ga4';
// import { styled } from '@mui/material/styles'
import reducer from '../utils/reducer';
import {stateFull, stateAbbr} from '../utils/states';





export default function AddressPage({stateShortName}) {    
    
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const stateMapping = {};
    stateAbbr.forEach( (oneAbbr, index) => {stateMapping[oneAbbr] = stateFull[index]});
        

    const initialState = { 
        street: '',
        city: '',
        state: searchParams.get("stateShort") || stateShortName,     
        fullState: stateMapping[searchParams.get("stateShort")] || stateMapping[stateShortName],    
        postalcode: ''
    };
    const [errors, setErrors] = useState({});    
    const [loading, setLoading] = useState(false);
    const [formInputs, dispatch] = useReducer(reducer, initialState);
    const [listOfStates, setListOfStates] = useState();

   useEffect(()=>{
    dispatch({
            type: "update",
            payload: {
                field: "fullState",
                value: stateMapping[stateShortName]
            }
        }); 
   },[stateShortName])
    
    const handleInputChange = (inputEvent) => {
        dispatch({
            type: "update",
            payload: {
                field: inputEvent.target.name,
                value: inputEvent.target.value
            }
        });               
    }

    const handleStateChange = (inputEvent) => {
        dispatch({
            type: "update",
            payload: {
                field: inputEvent.target.name,
                value: inputEvent.target.value
            }
        }); 

        dispatch({
            type: "update",
            payload: {
                field: "fullState",
                value: stateMapping[inputEvent.target.value]
            }
        }); 
    }

    // handle frontend errors
    const findFormErrors = () => {
        const {street, city, state, postalcode} = formInputs;
        const newErrors = {};
        
        if ( !street || street === '' ) {
            newErrors.street = 'cannot be blank!'; 
            newErrors.streetError = true;
        } else if (street.match(/[^#%&*:<>?/{|}]+/) === false) {
            newErrors.street = 'contains invalid characters';
            newErrors.streetError = true;
        }

        if ( !city || city === '' ) {
            newErrors.city = 'cannot be blank!'; 
            newErrors.cityError = true;
        } else if (city.match(/[^#%&*:<>?/{|}]+/) === false) {
            newErrors.city = 'contains invalid characters';
            newErrors.cityError = true;
        }

        if ( !state || state === '' ) {
            newErrors.state = 'cannot be blank!'; 
            newErrors.stateError = true;
        }

        if ( !postalcode || postalcode === '' ) {
            newErrors.postalcode = 'cannot be blank!'; 
            newErrors.postalcodeError = true;
        } else if (postalcode.length !== 5) {
            newErrors.postalcode = 'must contains 5 digits only';
            newErrors.postalcodeError = true;
        }

        return newErrors;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("ReactGA event was called");
        ReactGA.event({ 
            action: 'ballotFinderClick',
            category: 'ballotfinder_category',
            label: `State: ${formInputs.fullState}`
        });
        setErrors({});
        // get our new errors
        const newErrors = findFormErrors();
        
        if (Object.keys(newErrors).length > 0) {
            // We've got errors on the front end            
            setErrors(newErrors);
        } else {        
                setLoading(true);
                console.log("formInputs.fullState", formInputs.fullState);
                navigate(`/candidateResult?street=${formInputs.street}&city=${formInputs.city}&state=${formInputs.state}&fullState=${formInputs.fullState}&postalcode=${formInputs.postalcode}`);            
        }
    }

    const getStates = async () => {
        const response = await fetch(`/api/dropdown`);
        const result = await response.json();                   
        if (result.success) {
            setListOfStates(result.data.filter(oneState => oneState.stateActive === true));        
        }    
    }
    
    useEffect( () => {
        getStates();
        
    }, []);

    useEffect(()=>{
         dispatch({
            type: "update",
            payload: {
                field: "state",
                value: stateShortName
            }
        }); 

    },[stateShortName])

    // const TextFieldStyled = styled(TextField)(() => ({
    //     backgroundColor: 'white',
    //     borderRadius: '5px'
    // }));


    return (
        <Container sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh'}}>
            <Typography variant="h2" color="primary" mb={2}> 2024 General Election </Typography>
            { stateMapping && 
            <Box 
            noValidate
            autoComplete="off"
            sx={{ p: 2, border: '1px solid #9C532A', borderRadius: '5px', maxWidth: '500px'}}>
                <Typography align='left' gutterBottom="true" mb={2}>Enter Your Address to Find Your Candidates:</Typography>
                <Box sx={{ p: 2, my: 2, backgroundColor: "primary.dark", borderRadius: '5px'}}>
                    <Box sx={{ mb: 2 }}>
                        <TextField
                            variant="outlined"                                                
                            placeholder="Street"
                            size="small"
                            name="street"
                            fullWidth                                             
                            value={formInputs.street}            
                            onChange={handleInputChange}                            
                            error={errors?.streetError}
                            sx={{backgroundColor: "white", borderRadius: '5px'}}
                        />
                        {errors.street !== "" && <FormHelperText sx={{color: "white"}}>{ errors.street }</FormHelperText>}
                    </Box>                   
                    <Box component="div" sx={{display: 'flex', justifyContent: 'center'}}>
                        <div>
                        <TextField                                                                          
                            placeholder="City"
                            size="small"
                            name="city"
                            sx={{ mr: 2, backgroundColor: "white", borderRadius: '5px' }}  
                            value={formInputs.city}                            
                            onChange={handleInputChange}                            
                            error={errors?.cityError}
                        />
                        <FormHelperText sx={{color: "white"}}>{ errors.city }</FormHelperText>
                        </div>
                        <div>
                        <TextField                                          
                            placeholder="Zip Code"
                            size="small"
                            name="postalcode"
                            sx={{backgroundColor: "white", borderRadius: '5px'}}             
                            value={formInputs.postalcode}                 
                            onChange={handleInputChange}                            
                            maxLength="5"
                            type="number"                          
                            error={errors?.postalcodeError}
                        />
                        <FormHelperText sx={{color: "white"}}>{ errors.postalcode }</FormHelperText>
                        </div>
                    </Box> 
                    
                    <FormControl sx={{width: "60%", backgroundColor: "white", mt: 2, borderRadius: '5px'}}>
                        <InputLabel variant="filled" htmlFor="state-label" id="demo-simple-select-label">State</InputLabel>
                            { listOfStates && 
                            <Select            
                            id="state-label"                            
                            name="state"
                            helpertext={ errors.state }
                            error={errors?.stateError} 
                            value={formInputs.state}                           
                            onChange={handleStateChange}                                                                                                                                 
                            >         
                                <MenuItem key='none' value=''>Choose State</MenuItem>                                                       
                                { listOfStates.map(oneState => (
                                    <MenuItem key={oneState.stateShort} value={oneState.stateShort}>{oneState.stateFull}</MenuItem>
                                ))}                                                           
                            </Select>
                            }
                    </FormControl>                   
                </Box>
                { loading ? <CircularProgress /> : 
                    <Button variant="contained" sx={{ backgroundColor: "primary.dark"}} onClick={handleSubmit}>Find Candidates</Button>
                }
            </Box> }
                
            
        </Container>
    )
}

AddressPage.propTypes = {
    stateShortName: PropTypes.string
}