import React, {useState, useEffect, useReducer} from 'react'
import PropTypes from 'prop-types'
import {useParams, useNavigate} from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
// import Container from '@mui/material/Container'
import reducer from '../utils/reducer'
import categories from '../utils/categories'


export default function AdminEdit({role}) {
    const navigate = useNavigate();
    const [initialCat, setInitialCat] = useState('');
    const initialState = {};
    const {id} = useParams();
    const [file, setFile] = useState();
    const [initialImage, setInitialImage] = useState();
    const [loading, setLoading] = useState(false);
    const [formInputs, dispatch] = useReducer(reducer, initialState);   

    const handleInputChange = (inputEvent) => {
        dispatch({
            type: "update",
            payload: {
                field: inputEvent.target.name,
                value: inputEvent.target.value
            }
        });   
    }

    // const categories = [
    //     "Neoliberalism Explained",
    //     "Racism, Pride And Prejudice",
    //     "Unionizing 101",
    //     "Dictatorship of the Proletariat",
    //     "Conspiracy And Capitalism",
    //     "The Fetishization of Nonviolence",
    //     "The Follies of Capitalism",
    //     "Right Wing Rhetoric",
    //     "Why Voting Matters",        
    //     "Deconstructing Societal Norms And Narratives",
    //     "Spoils of the Imperial Core",
    //     "I Dream of Socialism",
    //     "So You Want to be a Capitalist?",
    //     "As We Wait for the Revolution",
    //     "So What Can We Do About It?"
    //   ];
      
      useEffect(() => {

        const getVideoDetails = async() => {
            
            const response = await fetch(`/api/books/${id}`);
            const data = await response.json();            
            if (data.success) {
                
                const tiktokId = data.data.url.split('/').slice(-1);
                dispatch({
                    type: "update",
                    payload: {
                        field: 'url',
                        value: tiktokId
                    }
                });

                dispatch({
                    type: "update",
                    payload: {
                        field: 'category',
                        value: data.data.category
                    }
                });

                setInitialCat(data.data.category);
                setInitialImage(data.data.thumbnail_url);

            } else {
                console.error('Error at backend')
            }            
        }
        
        getVideoDetails();

      }, [])

      const handleAmend = async() => {
        const data = new FormData();
        const tiktokUrl = `https://www.tiktok.com/@thedownballot.org/video/${formInputs.url}`
        data.append("url", tiktokUrl);
        data.append("category", formInputs.category);
        if (file) {
            console.log('file', file);
            data.append("image", file);
        }
        
        setLoading(true);
        const response = await fetch(`/api/books/${id}`, {
            method: 'PUT',            
            body: data
        });        
        const result = await response.json();
        console.log(result);

        if (result.success) {
            setLoading(false);            
            setInitialCat(result.amendedBook.category);
            setInitialImage(result.amendedBook.thumbnail_url);
            navigate('/admindashboard');
        }

      }

    return (
        <Box component="div" sx={{p: 2}}>  
            <Typography variant="h2" color="primary" sx={{mb: 3}}>Edit Video</Typography>
            { role === 'user' && <Typography>You are not an administrator</Typography>}
            { role === 'admin' && (
            <FormGroup sx={{border: '1px solid grey', borderRadius: '10px', p: 3}}>
            
                <FormControl variant="standard" sx={{ mb: 4 }}>
                    <Typography align="left">URL</Typography>  
                    <Input
                    id="input-with-start-adornment"
                    type="number"
                    placeholder="videoId"
                    name="url"   
                    required
                    value={formInputs.url}
                    onChange={handleInputChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <Typography sx={{fontSize: {xs: '12px', sm: '18px'}}}>www.tiktok.com/@thedownballot.org/video/</Typography>
                        </InputAdornment>
                    }
                    />
                </FormControl>
            
                <Typography align="left" sx={{fontWeight:'bold'}}>Current Category: {initialCat}</Typography>
                <FormControl sx={{ width: 300, mb: 4}}>    
                    <InputLabel htmlFor="demo-simple-select">Category</InputLabel>                
                    <Select                        
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"                                        
                        name="category"    
                        label="Category"
                        autoWidth                              
                        required    
                        value={formInputs.category}                  
                        onChange={handleInputChange}
                    >
                        {categories.map(category => (
                            <MenuItem key={category} value={category}>{category}</MenuItem>
                        ))}                
                    </Select>
                </FormControl>
                
                <Typography align="left" sx={{fontWeight:'bold'}}>Current image</Typography>
                <Box component="img" src={initialImage} sx={{width: "30%", mb: 2 }} alt="current image" />
                
                <FormControl variant="standard" sx={{ width: 300, mb: 4 }}>                    
                    <Input                    
                    type="file"
                    accept="image/png, image/jpeg"
                    name="image"       
                    onChange={(event) =>  setFile(event.target.files[0])}                                                         
                    />
                </FormControl>

                { loading ? <CircularProgress /> :
                <Button color="primary" variant="contained" onClick={handleAmend}>Submit</Button>
                }
            </FormGroup>          
            )}      
        </Box>
        
    )
}

AdminEdit.propTypes = {
    role: PropTypes.string.isRequired
}