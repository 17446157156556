import React from 'react';
import Button from '@mui/material/Button';

export default function Logout() {
    
    const handleLogout = () => {
        const deleteSession = async() => {
            const result = await fetch("/api/sessions", {
                method: 'DELETE'
            });
            const data = await result.json();
            console.log(data);
            if (data.success) {
               window.location.assign('/')
            }
          }
          deleteSession();
    }
    return (
        <Button variant="contained" color="error" onClick={handleLogout}>
            Logout
        </Button>
    )
}
