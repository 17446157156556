import React, {useState, useRef} from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button'
import AddressPage from '../AddressPage';

export default function MapHomepage() {
    const [selectedState, setSelectedState] = useState("")
    const addressPageRef = useRef(null)
    const handleStateClick = (stateShortName) => {
        setSelectedState(stateShortName)
        addressPageRef.current.scrollIntoView({behavior: 'smooth'})
    }

    /*
    const [listOfStates, setListOfStates] = useState();
    

    const getStates = async () => {
        const response = await fetch(`/api/dropdown`);
        const result = await response.json();                   
        if (result.success) {
            const results = result.data.filter(oneState => oneState.stateActive === true);
            setListOfStates(results);
        }    
        
    }
    
    useEffect( async () => {
        getStates()
        
    }, []);
    */
    const keyStates = [
        {
            name: "Wisconsin",
            image: "https://firebasestorage.googleapis.com/v0/b/thedownballot-b5d6b.appspot.com/o/1.png?alt=media&token=52e0ee40-ad92-43f7-84e8-89c2c835c24d",
            shortName: "WI"
        },
        {
            name: "Ohio",
            image: "https://firebasestorage.googleapis.com/v0/b/thedownballot-b5d6b.appspot.com/o/2.png?alt=media&token=04d436b3-2eba-4e14-8ee5-504ae2047245",
            shortName: "OH"

        },
        {
            name: "Pennsylvania",
            image: "https://firebasestorage.googleapis.com/v0/b/thedownballot-b5d6b.appspot.com/o/3.png?alt=media&token=69e8eea4-26cf-4bdb-b221-488950d10da1",
            shortName: "PA"

        },
        {
            name: "Georgia",
            image: "https://firebasestorage.googleapis.com/v0/b/thedownballot-b5d6b.appspot.com/o/5.png?alt=media&token=4de2efac-1a88-4013-b65c-3155599f4055",
            shortName: "GA"
            
        },
        {
            name: "New York",
            image: "https://firebasestorage.googleapis.com/v0/b/thedownballot-b5d6b.appspot.com/o/6.png?alt=media&token=dbc0ed65-6b43-4eed-a792-ea92c8da5861",
            shortName: "NY"

        },
        {
            name: "Arizona",
            image: "https://firebasestorage.googleapis.com/v0/b/thedownballot-b5d6b.appspot.com/o/7.png?alt=media&token=fe693e14-716b-4abf-96d5-e38215a8780d",
            shortName: "AZ"

        }
    ]
    
    return (
        <Container>
            { /*
        <Typography variant="h4" my={2} color="primary">Click on your state to view your candidates who will be on your midterm ballot!</Typography>
        */ }
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <Typography color="primary" sx={{ p: 2, width: "80%", border: "1px solid #9e522a", borderRadius: "10px" }}>
                    Welcome to thedownballot.org! We&apos;re thrilled you&apos;re here. We&apos;re preparing for the 2024 General Election, launching on October 29th. Since we&apos;re a small team, we&apos;ll cover the states listed below but aim to expand nationwide in the future. Follow us on Instagram and TikTok for updates!

                    <Box sx={{ whiteSpace: "pre-wrap" }}> </Box>

                <Typography color="primary" sx={{p: 2, width: "80%", margin: 'auto'}}>
                    DISCLAIMER: We will only be providing coverage for Democrat candidates at this time.
                </Typography>
                </Typography>

                
            </Box>

            <Grid container rowSpacing={2} columnSpacing={2} sx={{
                mt: 2,
                ml: { xs: -2, sm: 9 },
                justifyContent: "center",
                alignItems: "center",
            }}
            >
                {keyStates.map((oneMap) => (
                    <Grid item xs={4} key={oneMap.shortName} onClick={() => handleStateClick(oneMap.shortName)} sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Box
                            component="div"

                            sx={{
                                my: 2,
                                minWidth: '80px',
                                maxWidth: '200px',
                                backgroundColor: '#9e522a',
                                "&:hover": { cursor: "pointer" }
                                
                            }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    width: '100%',
                                }}
                                src={oneMap.image}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <Box ref={addressPageRef}>
                <AddressPage stateShortName={selectedState} />
            </Box>
        
        <Box sx={{display: "flex", justifyContent: "center", mt: -10}}>
            <Typography color="primary" sx={{ p: 2, width: "80%", border: "1px solid #9e522a", borderRadius: "10px"}}>As a small organization, we chose to focus our coverage on a few states and key races across the country. 
            However, our friends at <Link href="https://acti.vote/thedownballot">activote.net</Link> have nationwide coverage that we encourage you to check out! Simply click on their logos to navigate to their voting guides!</Typography>
        </Box>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 2 }} sx={{justifyContent: "center", alignItems: "center"}}>
            <Grid item xs={4} sm={4} md={6} sx={{justifyContent: "center", display: "flex"}}>
                <Box 
                    component="div"
                    sx={{
                        my: 2,
                        minWidth: '80px',
                        maxWidth: '200px',
                        backgroundColor: '#9e522a'
                    }}
                >
                    <Box 
                        component='img'
                        sx={{
                            width: '80%',
                            p: 2
                        }}
                        src="/mapsLanding/activote.png" />
                    
                    <Box component="div" sx={{backgroundColor: '#9e522a'}}>
                        <Button 
                        variant="outlined" 
                        href="https://acti.vote/thedownballot"
                        sx={{borderRadius: "10px", mb: 2, backgroundColor: "white", "&:hover": {color: "white"}}}>
                            ACTIVOTE</Button>
                    </Box>
                    
                </Box>
            </Grid>
        </Grid>   
        
        </Container>
    )
}
