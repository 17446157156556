import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import CandidateProfile from './CandidateProfile';
import OfficeDuties from './OfficeDuties';

export default function CandidateSection({heading, candidates, districtName}) {    
    return (
        <Box component="div" sx={{ mb:2, p: 2, border: '1px solid #C86734', borderRadius: '10px'}}>
                <OfficeDuties title={heading} />          
                <Typography my={2} variant="h5">{districtName}</Typography>      
                <Box component="div" sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly'}}>
                { candidates.map( (candidate) => (
                        <CandidateProfile key={candidate} 
                            candidate={candidate}                             
                            candidatePage={false} 
                        />
                ))
                }
                </Box>
        </Box>
    )
}

CandidateSection.propTypes = {
    heading: PropTypes.string.isRequired,
    candidates: PropTypes.instanceOf(Array).isRequired,   
    districtName: PropTypes.string
}