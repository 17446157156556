import React from 'react'

export default function DownballotLogo() {
    
    return (
        <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 191 132" xmlns="http://www.w3.org/2000/svg" version="1.0">
            <title>DownBallot</title>
            <g>
            <title>Layer 1</title>
            <path fill="#742713" d="m80.67578,2.99019l-80.67578,0l0,125.99219l2.24219,0l0,-123.73438l83.25781,0l0,-2.25781l-4.82422,0" id="svg_1"/>
            <g fill="#742713" id="svg_2">
            <g id="svg_3">
                <g id="svg_4">
                <path d="m104.91263,30.125l1.70312,0c0.53125,0 0.97656,-0.17969 1.34375,-0.54688c0.375,-0.375 0.5625,-0.82812 0.5625,-1.35937l0,-23.76563c0,-0.53906 -0.1875,-0.99218 -0.5625,-1.35937c-0.36719,-0.36328 -0.8125,-0.54688 -1.34375,-0.54688l-1.48437,0c-0.90625,0 -1.7461,0.15235 -2.51563,0.45313c-0.76172,0.29297 -1.43359,0.69922 -2.01562,1.21875c-0.57422,0.52344 -1.03907,1.13281 -1.39063,1.82812c-0.35547,0.6875 -0.57422,1.42969 -0.65625,2.21875l-0.21875,1.92188l-0.84375,0l0,-10.1875l0.84375,0l0.21875,1.48437l23.96875,0l0.21875,-1.48437l0.84375,0l0,10.1875l-0.84375,0l-0.21875,-1.92188c-0.08594,-0.78906 -0.30469,-1.53125 -0.65625,-2.21875c-0.35547,-0.69531 -0.82422,-1.30468 -1.40625,-1.82812c-0.57422,-0.51953 -1.24609,-0.92578 -2.01562,-1.21875c-0.76172,-0.30078 -1.59375,-0.45313 -2.5,-0.45313l-3.39063,0l0,27.57813l3.39063,0l0,1.0625l-11.03125,0l0,-1.0625zm0,0" id="svg_5"/>
                </g>
            </g>
            </g>
            <g fill="#742713" id="svg_6">
            <g id="svg_7">
                <g id="svg_8">
                <path d="m127.82875,31.125l1.6875,0c0.53906,0 0.99219,-0.17969 1.35938,-0.54688c0.375,-0.375 0.5625,-0.82812 0.5625,-1.35937l0,-23.76563c0,-0.53906 -0.1875,-0.99218 -0.5625,-1.35937c-0.36719,-0.36328 -0.82032,-0.54688 -1.35938,-0.54688l-1.6875,0l0,-1.0625l11.03125,0l0,1.0625l-3.39062,0l0,12.09375l15.90625,0l0,-10.1875c0,-0.53906 -0.1836,-0.99218 -0.54688,-1.35937c-0.36719,-0.36328 -0.82031,-0.54688 -1.35937,-0.54688l-1.70313,0l0,-1.0625l11.03125,0l0,1.0625l-3.39062,0l0,27.57813l3.39062,0l0,1.0625l-11.03125,0l0,-1.0625l1.70313,0c0.53906,0 0.99218,-0.17969 1.35937,-0.54688c0.36328,-0.375 0.54688,-0.82812 0.54688,-1.35937l0,-12.73438l-15.90625,0l0,14.64063l3.39062,0l0,1.0625l-11.03125,0l0,-1.0625zm0,0" id="svg_9"/>
                </g>
            </g>
            </g>
            <g fill="#742713" id="svg_10">
            <g id="svg_11">
                <g id="svg_12">
                <path d="m163.04575,31.125l1.6875,0c0.53906,0 0.99219,-0.17969 1.35938,-0.54688c0.375,-0.375 0.5625,-0.82812 0.5625,-1.35937l0,-23.76563c0,-0.53906 -0.1875,-0.99218 -0.5625,-1.35937c-0.36719,-0.36328 -0.82032,-0.54688 -1.35938,-0.54688l-1.6875,0l0,-1.0625l22.48438,0l0.21875,-1.48437l0.84375,0l0,10.1875l-0.79688,0l-0.46875,-2.34375c-0.34375,-1.69531 -1.14062,-3.00391 -2.39062,-3.92188c-1.24219,-0.91406 -3.13282,-1.375 -5.67188,-1.375l-6.57812,0l0,12.09375l5.71875,0c1.97656,0 2.96875,-0.98828 2.96875,-2.96875l0,-1.70312l0.85937,0l0,10.1875l-0.85937,0l0,-1.70313c0,-1.97656 -0.99219,-2.96875 -2.96875,-2.96875l-5.71875,0l0,14.64063l7.64062,0c1.32031,0 2.45703,-0.125 3.40625,-0.375c0.94531,-0.25781 1.73828,-0.61719 2.375,-1.07813c0.63281,-0.46875 1.13281,-1.02343 1.5,-1.67187c0.375,-0.65625 0.63281,-1.37891 0.78125,-2.17188l0.42188,-2.34375l0.84375,0l0,8.70313l-24.60938,0l0,-1.0625zm0,0" id="svg_13"/>
                </g>
            </g>
            </g>
            <g fill="#742713" id="svg_14">
            <g id="svg_15">
                <g id="svg_16">
                <path d="m41.27776,79.505l1.6875,0c0.53906,0 0.99219,-0.17969 1.35938,-0.54688c0.375,-0.375 0.5625,-0.82812 0.5625,-1.35937l0,-23.76563c0,-0.53906 -0.1875,-0.99218 -0.5625,-1.35937c-0.36719,-0.36328 -0.82032,-0.54688 -1.35938,-0.54688l-1.6875,0l0,-1.0625l13.15625,0c2.3125,0 4.42188,0.3711 6.32813,1.10938c1.91406,0.73047 3.55078,1.75781 4.90625,3.07812c1.36328,1.3125 2.41015,2.875 3.14062,4.6875c0.73828,1.80469 1.10938,3.79297 1.10938,5.96875c0,2.1875 -0.3711,4.1875 -1.10938,6c-0.73047,1.80469 -1.77734,3.36719 -3.14062,4.6875c-1.35547,1.3125 -2.99219,2.33985 -4.90625,3.07813c-1.90625,0.73047 -4.01563,1.09375 -6.32813,1.09375l-13.15625,0l0,-1.0625zm13.15625,0c1.58203,0 3.05078,-0.28516 4.40625,-0.85938c1.36328,-0.58203 2.53125,-1.45312 3.5,-2.60937c0.97656,-1.16406 1.74219,-2.60156 2.29688,-4.3125c0.55078,-1.71875 0.82812,-3.72266 0.82812,-6.01563c0,-2.28906 -0.27734,-4.28906 -0.82812,-6c-0.55469,-1.70703 -1.32032,-3.14062 -2.29688,-4.29687c-0.96875,-1.16406 -2.13672,-2.03516 -3.5,-2.60938c-1.35547,-0.58203 -2.82422,-0.875 -4.40625,-0.875l-5.51562,0l0,27.57813l5.51562,0zm0,0" id="svg_17"/>
                </g>
            </g>
            </g>
            <g fill="#742713" id="svg_18">
            <g id="svg_19">
                <g id="svg_20">
                <path d="m91.13475,81.41125c-2.125,0 -4.11719,-0.40234 -5.96875,-1.20312c-1.85547,-0.80079 -3.47656,-1.89844 -4.85937,-3.29688c-1.38672,-1.40625 -2.47657,-3.0625 -3.26563,-4.96875c-0.79297,-1.91406 -1.1875,-3.99219 -1.1875,-6.23438c0,-2.22656 0.39453,-4.29687 1.1875,-6.20312c0.78906,-1.91406 1.87891,-3.57031 3.26563,-4.96875c1.38281,-1.40625 3.0039,-2.50781 4.85937,-3.3125c1.85156,-0.8125 3.84375,-1.21875 5.96875,-1.21875c2.125,0 4.10938,0.40625 5.95313,1.21875c1.85156,0.80469 3.47265,1.90625 4.85937,3.3125c1.39453,1.39844 2.48828,3.05469 3.28125,4.96875c0.78906,1.90625 1.1875,3.97656 1.1875,6.20312c0,2.24219 -0.39844,4.32032 -1.1875,6.23438c-0.79297,1.90625 -1.88672,3.5625 -3.28125,4.96875c-1.38672,1.39844 -3.00781,2.49609 -4.85937,3.29687c-1.84375,0.80079 -3.82813,1.20313 -5.95313,1.20313zm0,-1.0625c1.4375,0 2.80469,-0.31641 4.10938,-0.95313c1.30078,-0.63281 2.44531,-1.5664 3.4375,-2.79687c0.98828,-1.22656 1.78125,-2.75391 2.375,-4.57813c0.59375,-1.82031 0.89062,-3.92578 0.89062,-6.3125c0,-2.375 -0.29687,-4.47265 -0.89062,-6.29687c-0.59375,-1.82031 -1.38672,-3.34766 -2.375,-4.57813c-0.99219,-1.22656 -2.13672,-2.16015 -3.4375,-2.79687c-1.30469,-0.64453 -2.67188,-0.96875 -4.10938,-0.96875c-1.44922,0 -2.82422,0.32422 -4.125,0.96875c-1.30469,0.63672 -2.44922,1.57031 -3.4375,2.79687c-0.99219,1.23047 -1.78125,2.75782 -2.375,4.57813c-0.59375,1.82422 -0.89062,3.92187 -0.89062,6.29687c0,2.38672 0.29687,4.49219 0.89062,6.3125c0.59375,1.82422 1.38281,3.35157 2.375,4.57813c0.98828,1.23047 2.13281,2.16406 3.4375,2.79687c1.30078,0.63672 2.67578,0.95313 4.125,0.95313zm0,0" id="svg_21"/>
                </g>
            </g>
            </g>
            <g fill="#742713" id="svg_22">
            <g id="svg_23">
                <g id="svg_24">
                <path d="m124.65601,81.20813c-1.89844,-4.46876 -3.80859,-8.91407 -5.73437,-13.34376c-1.92969,-4.42578 -3.83985,-8.89062 -5.73438,-13.39062c-0.19922,-0.45703 -0.39062,-0.84766 -0.57812,-1.17188c-0.17969,-0.32031 -0.38282,-0.58203 -0.60938,-0.78125c-0.21875,-0.19531 -0.49609,-0.34375 -0.82812,-0.4375c-0.32422,-0.10156 -0.71094,-0.15625 -1.15625,-0.15625l-1.06251,0l0,-1.0625l10.39063,0l0,1.0625l-2.75,0l10.1875,23.76563l5.21875,-13.45313l-3.3125,-7.76562c-0.19922,-0.45703 -0.39062,-0.84375 -0.57812,-1.15625c-0.1875,-0.3125 -0.39844,-0.57031 -0.625,-0.78125c-0.21875,-0.20703 -0.49219,-0.35938 -0.8125,-0.45313c-0.32422,-0.10156 -0.71485,-0.15625 -1.17188,-0.15625l-1.26562,0l0,-1.0625l17.39062,0l0,1.0625l-1.6875,0c-0.85547,0 -1.55469,0.17969 -2.09375,0.53125c-0.53125,0.35547 -0.96875,0.95313 -1.3125,1.79688l-1.6875,4.25l7.42188,17.1875l9.125,-23.76563l-3.1875,0l0,-1.0625l8.70312,0l0,1.0625l-1.70312,0c-0.84375,0 -1.5625,0.1836 -2.15625,0.54688c-0.59375,0.36719 -1.07813,1.03125 -1.45313,2l-10.17187,26.73438l-1.28125,0l-7.51563,-17.40626l-6.70312,17.40626l-1.26563,0zm11.45313,-29.28126l-4.03125,0l2.125,5.09375l1.90625,-5.09375zm0,0" id="svg_25"/>
                </g>
            </g>
            </g>
            <g fill="#742713" id="svg_26">
            <g id="svg_27">
                <g id="svg_28">
                <path d="m162.83114,55.09875l0,24.40625l3.60937,0l0,1.0625l-8.26562,0l0,-1.0625l0.84375,0c0.95703,0 1.65625,-0.22266 2.09375,-0.67188c0.44531,-0.45703 0.67187,-1.22265 0.67187,-2.29687l0,-22.70313c0,-0.53906 -0.1875,-0.99218 -0.5625,-1.35937c-0.36719,-0.36328 -0.82031,-0.54688 -1.35937,-0.54688l-1.6875,0l0,-1.0625l6.73437,0l19.14063,23.34375l0,-22.28125l-3.39063,0l0,-1.0625l8.0625,0l0,1.0625l-0.84375,0c-0.96875,0 -1.67187,0.23438 -2.10937,0.70313c-0.4375,0.46094 -0.65625,1.23047 -0.65625,2.3125l0,26.26563l-0.84375,0l-21.4375,-26.10938zm0,0" id="svg_29"/>
                </g>
            </g>
            </g>
            <g fill="#742713" id="svg_30">
            <g id="svg_31">
                <g id="svg_32">
                <path d="m9.44975,127.884l1.6875,0c0.53906,0 0.99219,-0.17969 1.35938,-0.54688c0.375,-0.375 0.5625,-0.82812 0.5625,-1.35937l0,-23.76563c0,-0.53906 -0.1875,-0.99218 -0.5625,-1.35937c-0.36719,-0.36328 -0.82032,-0.54688 -1.35938,-0.54688l-1.6875,0l0,-1.0625l12.73438,0c1.80078,0 3.34765,0.17969 4.64062,0.53125c1.28906,0.35547 2.34375,0.83594 3.15625,1.4375c0.82031,0.60547 1.42188,1.32032 1.79688,2.14063c0.38281,0.82422 0.57812,1.71484 0.57812,2.67187c0,1.44922 -0.35156,2.60547 -1.04687,3.46875c-0.6875,0.86719 -1.4375,1.52344 -2.25,1.96875c-0.96875,0.54297 -2.0625,0.92188 -3.28125,1.14063l0,0.21875c1.75781,0.19922 3.3164,0.60937 4.67187,1.23437c0.59375,0.25 1.16406,0.57422 1.71875,0.96875c0.55078,0.39844 1.04688,0.85938 1.48438,1.39063c0.4375,0.52344 0.78906,1.13672 1.0625,1.84375c0.26953,0.69922 0.40625,1.5 0.40625,2.40625c0,1.21875 -0.24219,2.33984 -0.71875,3.35937c-0.48047,1.02344 -1.21094,1.89063 -2.1875,2.60938c-0.98047,0.71875 -2.21875,1.28906 -3.71875,1.70312c-1.5,0.40625 -3.25,0.60938 -5.25,0.60938l-13.79688,0l0,-1.0625zm13.15625,0c2.57031,0 4.55469,-0.61328 5.95313,-1.84375c1.40625,-1.22656 2.10937,-3.01953 2.10937,-5.375c0,-2.11328 -0.71094,-3.84375 -2.125,-5.1875c-1.41797,-1.34375 -3.39844,-2.01563 -5.9375,-2.01563l-5.51562,0l0,14.42188l5.51562,0zm-1.28125,-15.28125c2.125,0 3.75,-0.53906 4.875,-1.625c1.13281,-1.09375 1.70313,-2.59766 1.70313,-4.51563c0,-1.92578 -0.57032,-3.42968 -1.70313,-4.51562c-1.125,-1.09375 -2.75,-1.64063 -4.875,-1.64063l-4.23437,0l0,12.29688l4.23437,0zm0,0" id="svg_33"/>
                </g>
            </g>
            </g>
            <g fill="#742713" id="svg_34">
            <g id="svg_35">
                <g id="svg_36">
                <path d="m37.444,127.884l1.28125,0c0.95703,0 1.67969,-0.20703 2.17188,-0.625c0.5,-0.42578 0.90625,-1.06641 1.21875,-1.92188l9.76562,-26.73437l1.90625,0l10.1875,26.73437c0.34375,0.90625 0.71875,1.5586 1.125,1.95313c0.40625,0.39844 1.09375,0.59375 2.0625,0.59375l1.0625,0l0,1.0625l-10.60937,0l0,-1.0625l2.96875,0l-3.26563,-8.48438l-11.67187,0l-3.10938,8.48438l2.76563,0l0,1.0625l-7.85938,0l0,-1.0625zm19.53125,-9.32813l-5.51562,-15.07812l-5.51563,15.07812l11.03125,0zm0,0" id="svg_37"/>
                </g>
            </g>
            </g>
            <g fill="#742713" id="svg_38">
            <g id="svg_39">
                <g id="svg_40">
                <path d="m70.12476,127.884l1.6875,0c0.53906,0 0.99219,-0.17969 1.35938,-0.54688c0.375,-0.375 0.5625,-0.82812 0.5625,-1.35937l0,-23.76563c0,-0.53906 -0.1875,-0.99218 -0.5625,-1.35937c-0.36719,-0.36328 -0.82032,-0.54688 -1.35938,-0.54688l-1.6875,0l0,-1.0625l11.45313,0l0,1.0625l-3.8125,0l0,27.57813l6.15625,0c1.32031,0 2.45703,-0.125 3.40625,-0.375c0.94531,-0.25781 1.73828,-0.61719 2.375,-1.07813c0.63281,-0.46875 1.13281,-1.02343 1.5,-1.67187c0.375,-0.65625 0.63281,-1.37891 0.78125,-2.17188l0.42187,-2.34375l0.84375,0l0,8.70313l-23.125,0l0,-1.0625zm0,0" id="svg_41"/>
                </g>
            </g>
            </g>
            <g fill="#742713" id="svg_42">
            <g id="svg_43">
                <g id="svg_44">
                <path d="m97.91675,127.884l1.6875,0c0.53906,0 0.99219,-0.17969 1.35938,-0.54688c0.375,-0.375 0.5625,-0.82812 0.5625,-1.35937l0,-23.76563c0,-0.53906 -0.1875,-0.99218 -0.5625,-1.35937c-0.36719,-0.36328 -0.82032,-0.54688 -1.35938,-0.54688l-1.6875,0l0,-1.0625l11.45313,0l0,1.0625l-3.8125,0l0,27.57813l6.15625,0c1.32031,0 2.45703,-0.125 3.40625,-0.375c0.94531,-0.25781 1.73828,-0.61719 2.375,-1.07813c0.63281,-0.46875 1.13281,-1.02343 1.5,-1.67187c0.375,-0.65625 0.63281,-1.37891 0.78125,-2.17188l0.42187,-2.34375l0.84375,0l0,8.70313l-23.125,0l0,-1.0625zm0,0" id="svg_45"/>
                </g>
            </g>
            </g>
            <g fill="#742713" id="svg_46">
            <g id="svg_47">
                <g id="svg_48">
                <path d="m141.83376,129.79025c-2.125,0 -4.11719,-0.40234 -5.96875,-1.20312c-1.85547,-0.80079 -3.47656,-1.89844 -4.85937,-3.29688c-1.38672,-1.40625 -2.47657,-3.0625 -3.26563,-4.96875c-0.79297,-1.91406 -1.1875,-3.99219 -1.1875,-6.23438c0,-2.22656 0.39453,-4.29687 1.1875,-6.20312c0.78906,-1.91406 1.87891,-3.57031 3.26563,-4.96875c1.38281,-1.40625 3.0039,-2.50781 4.85937,-3.3125c1.85156,-0.8125 3.84375,-1.21875 5.96875,-1.21875c2.125,0 4.10938,0.40625 5.95313,1.21875c1.85156,0.80469 3.47265,1.90625 4.85937,3.3125c1.39453,1.39844 2.48828,3.05469 3.28125,4.96875c0.78906,1.90625 1.1875,3.97656 1.1875,6.20312c0,2.24219 -0.39844,4.32032 -1.1875,6.23438c-0.79297,1.90625 -1.88672,3.5625 -3.28125,4.96875c-1.38672,1.39844 -3.00781,2.49609 -4.85937,3.29687c-1.84375,0.80079 -3.82813,1.20313 -5.95313,1.20313zm0,-1.0625c1.4375,0 2.80469,-0.31641 4.10938,-0.95313c1.30078,-0.63281 2.44531,-1.5664 3.4375,-2.79687c0.98828,-1.22656 1.78125,-2.75391 2.375,-4.57813c0.59375,-1.82031 0.89062,-3.92578 0.89062,-6.3125c0,-2.375 -0.29687,-4.47265 -0.89062,-6.29687c-0.59375,-1.82031 -1.38672,-3.34766 -2.375,-4.57813c-0.99219,-1.22656 -2.13672,-2.16015 -3.4375,-2.79687c-1.30469,-0.64453 -2.67188,-0.96875 -4.10938,-0.96875c-1.44922,0 -2.82422,0.32422 -4.125,0.96875c-1.30469,0.63672 -2.44922,1.57031 -3.4375,2.79687c-0.99219,1.23047 -1.78125,2.75782 -2.375,4.57813c-0.59375,1.82422 -0.89062,3.92187 -0.89062,6.29687c0,2.38672 0.29687,4.49219 0.89062,6.3125c0.59375,1.82422 1.38281,3.35157 2.375,4.57813c0.98828,1.23047 2.13281,2.16406 3.4375,2.79687c1.30078,0.63672 2.67578,0.95313 4.125,0.95313zm0,0" id="svg_49"/>
                </g>
            </g>
            </g>
            <g fill="#742713" id="svg_50">
            <g id="svg_51">
                <g id="svg_52">
                <path d="m169.62064,127.884l1.70312,0c0.53125,0 0.97656,-0.17969 1.34375,-0.54688c0.375,-0.375 0.5625,-0.82812 0.5625,-1.35937l0,-23.76563c0,-0.53906 -0.1875,-0.99218 -0.5625,-1.35937c-0.36719,-0.36328 -0.8125,-0.54688 -1.34375,-0.54688l-1.48437,0c-0.90625,0 -1.7461,0.15235 -2.51563,0.45313c-0.76172,0.29297 -1.43359,0.69922 -2.01562,1.21875c-0.57422,0.52344 -1.03907,1.13281 -1.39063,1.82812c-0.35547,0.6875 -0.57422,1.42969 -0.65625,2.21875l-0.21875,1.92188l-0.84375,0l0,-10.1875l0.84375,0l0.21875,1.48437l23.96875,0l0.21875,-1.48437l0.84375,0l0,10.1875l-0.84375,0l-0.21875,-1.92188c-0.08594,-0.78906 -0.30469,-1.53125 -0.65625,-2.21875c-0.35547,-0.69531 -0.82422,-1.30468 -1.40625,-1.82812c-0.57422,-0.51953 -1.24609,-0.92578 -2.01562,-1.21875c-0.76172,-0.30078 -1.59375,-0.45313 -2.5,-0.45313l-3.39063,0l0,27.57813l3.39063,0l0,1.0625l-11.03125,0l0,-1.0625zm0,0" id="svg_53"/>
                </g>
            </g>
            </g>
            </g>

            </svg>
    )
}

