import React, { useCallback, useState, useRef, useEffect } from 'react'
import axios from 'axios'
import Typography from '@mui/material/Typography' 
import Box from '@mui/material/Box' 
import Button from '@mui/material/Button'
import StoryCard from '../components/StoryCard'
import loadMoreVideos from '../hooks/loadMoreVideos'
import AdminAddMore from '../components/AdminAddMore'
import Logout from '../components/Logout'
import Scroll from '../components/Scroll'


export default function AdminDashboard() {    
    const [pageNumber, setPageNumber] = useState(1);
    const newestFirstArg = true;
    const { loading, videos, hasMore } = loadMoreVideos(pageNumber, '', newestFirstArg);
    const [videoState, setVideoState] = useState([]);   
    
    const observer = useRef();
    const lastSectionRef = useCallback( node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if(entries[0].isIntersecting && hasMore) {
                console.log('Visible');
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    const handleDelete = async(idParam) => {
        const response = await axios.delete(`/api/books/${idParam}`);
        setVideoState( videoState.filter( (video) => video._id !== idParam ) );
        console.log(response);
    }

    useEffect(() => {
        setVideoState(videos);
    }, [videos])

    return (        
        <Box sx={{ minHeight: '90vh'}}>
            <Typography variant="h2" color='primary.main' sx={{margin: '2rem 0 2rem 0px'}}>Admin Dashboard</Typography>
            <Logout />
            <AdminAddMore />
            <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            { videoState.length > 0 && videoState.map( (book, index) => (
                <Box component="div" ref={videoState.length === index+1 ? lastSectionRef : undefined} mb={2} px={1} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography>{book.category}</Typography>
                    <StoryCard
                    /* eslint-disable-next-line no-underscore-dangle */
                    key={book._id}
                    /* eslint-disable-next-line no-underscore-dangle */
                    itemId={book._id} // NOTE: itemId is required for track items
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    image={book.thumbnail_url}
                    url={book.url}
                    noMargin
                    />
                    <Box component="div">
                        <Button color="success" variant="contained" size="small" href={`/adminedit/${book._id}`} sx={{mr: 2}}> Edit Video</Button>
                        <Button color="error" variant="contained" size="small" onClick={() => handleDelete(book._id)}>Delete</Button>
                    </Box>
                    { videoState.length === index + 1 && <Scroll showBelow={200} />}
                </Box>
            ))}
            </Box>
            
        </Box>
    )
}

