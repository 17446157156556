import React, {useState, useEffect} from "react";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import "./SearchResultsPage.css";
import useGoogleSearch from "../../hooks/useGoogleSearch";
import { useStateValue } from "../../utils/StateProvider";


function SearchPage() {
  const [{ term }] = useStateValue();
  const [keywordDropdown, setKeywordDropdown] = useState("all");
  const [dataSelected, setDataSelected] = useState();

  // LIVE API CALL
  const { dataResponse } = useGoogleSearch(term);

  const handleKeywordDropdown = (event) => {
    setKeywordDropdown(event.target.value);
  }  

  useEffect( () => {
      if(keywordDropdown === "all") {
        setDataSelected(dataResponse?.data);
      } else {
        const dataFiltered = dataResponse?.data.find( (categoryChosen) => categoryChosen.category === keywordDropdown );
        console.log("dataFiltered", dataFiltered);
        setDataSelected([dataFiltered]);
      }    
      console.log("dataSelected", dataSelected);
  }, [keywordDropdown]);

  useEffect( () => {
    setDataSelected(dataResponse?.data);
    window.scrollTo(10, 10);
  }, [dataResponse]);
  
  return (
    <Container sx={{ p: 2, minHeight: '100vh', width: "90%"}}>
        <Typography variant="h2" color='primary.main' sx={{margin: '2rem 0 2rem 0px'}}>Search Results</Typography>            
        <FormControl>
            <InputLabel id="keywordId">Keyword</InputLabel>
            <Select
                labelId="keywordId"
                id="keywordId1"
                value={keywordDropdown}
                label="keyword"
                name="keyword"
                onChange={handleKeywordDropdown}
            >
                <MenuItem value="all">Show All</MenuItem>   
                { dataResponse?.data.map((oneCategory) => ( 
                    <MenuItem value={oneCategory.category}>{oneCategory.category}</MenuItem>
                ))}                                                                                           
            </Select>
        </FormControl>
      {term && dataSelected && (
        <div className="searchPage__results">
          

          {dataSelected?.map((oneCategory) => (
              oneCategory.axiosRes.map( (item) => (
                <div className="searchPage__result">
                <div style={{"margin-bottom": "10px", "font-weight": "bold"}}>Keyword: {oneCategory.category}</div>                 
                {item.pagemap?.cse_image?.length > 0 &&
                  item.pagemap?.cse_image[0]?.src && (
                    <img
                      className="searchPage__resultImage"
                      src={item.pagemap?.cse_image[0]?.src}
                      alt=""
                    />
                  )}
              <a className="searchPage__resultTitle" href={item.link} target="_blank" rel="noopener noreferrer">
                <h2>{item.title}</h2>
              </a>
              <p className="searchPage__resultSnippet">{item.snippet}</p>
            </div>
          ))))

          }
        </div>
      )}
    </Container>
  );
}

export default SearchPage;
