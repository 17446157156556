import React,{useState,useEffect} from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#A4B7A5',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function Sponsorship({candidateParam}) {
    
    const [sponsorResults, setSponsorResults] = useState();
    const [loading, setLoading] = useState(true);

    const getSponsorResults = async () => {
        const response = await fetch(`/api/donors?firstName=${candidateParam.firstName}&lastName=${candidateParam.lastName}&chamber=${candidateParam.office}`);
        const result = await response.json();      
        console.log(result);
        setLoading(false);
       
        setSponsorResults(result.data.response.contributors.contributor);
        
    }
   
    useEffect( () => {
        getSponsorResults();
    }, []);
    

    return (
      <Box my={3}>
      <Typography variant='h2'>Campaign Donors</Typography><br /> 
      { loading && <div>Loading......</div> }  
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Donor Title</StyledTableCell>
            <StyledTableCell align="right">Political Action Committees($)</StyledTableCell>
            <StyledTableCell align="right">Individuals($)</StyledTableCell>
            <StyledTableCell align="right">Total($)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {sponsorResults && sponsorResults.length > 0 && sponsorResults.map((sponsor) => ( 
            <StyledTableRow key={sponsor['@attributes'].org_name}>
              <StyledTableCell component="th" scope="row">{sponsor['@attributes'].org_name}</StyledTableCell>
              <StyledTableCell align="right">{sponsor['@attributes'].pacs}</StyledTableCell>
              <StyledTableCell align="right">{sponsor['@attributes'].indivs}</StyledTableCell>          
              <StyledTableCell align="right">{sponsor['@attributes'].total}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        </Table>
      </TableContainer>
      <Typography variant='body1' sx={{mt: 1}}>Source: <Link href="https://www.opensecrets.org/">https://www.opensecrets.org/</Link></Typography>
    </Box>
    );
};

Sponsorship.propTypes = {
  candidateParam: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      district: PropTypes.string.isRequired,
      office: PropTypes.string.isRequired
  }).isRequired
}